import * as Preact from "preact";
import logger from "./logger";
import locales from "../constants/supportedLocalesList";
import state from "./state";
import { LANGUAGE_NAMES, PRIMARY_DIALECTS } from "../constants/localesList";
import { TState } from "../hooks/useLocaleManager";

const SUPPORTED_LOCALES: string[] = locales;

const DEFAULT_LOCALE: string = SUPPORTED_LOCALES[0];

interface ILocaleData {
  [key: string]: string;
}

interface ILocaleConfig {
  locale: string;
  url: string;
  title: string;
}

type TPrimaryDialect = keyof typeof PRIMARY_DIALECTS;

let currentLocale: string = DEFAULT_LOCALE;
let isLocaleSet: boolean = false;
let localesConfig: ILocaleConfig[] = [];
let cachedLocales: { [key: string]: ILocaleData } = {};

/**
 * Sets the locale by dynamically importing the corresponding locale file.
 * Caches the locale data to avoid redundant network requests.
 * @param locale - The locale code to set.
 * @returns A promise that resolves when the locale is loaded.
 */
const setLocale = (locale: string): Promise<void> => {
  const fullLocale = getFullLocale(locale);

  // Check if the locale is already set
  if (
    currentLocale === fullLocale &&
    isLocaleSet &&
    cachedLocales[fullLocale]
  ) {
    return Promise.resolve();
  }

  // Check if the locale is already cached
  if (cachedLocales[fullLocale]) {
    currentLocale = fullLocale;

    return Promise.resolve();
  }

  const importLocale = (retry = false) => {
    return import(
      `../locales/${fullLocale}.js${retry ? `?timestamp=${Date.now()}` : ""}`
    )
      .then((localeData) => {
        cachedLocales[fullLocale] = localeData.default || localeData;
        currentLocale = fullLocale;
      })
      .catch((error) => {
        logger.error(`Error loading locale ${fullLocale}:`, { error });

        throw error;
      });
  };

  return importLocale()
    .catch(() => importLocale(true))
    .catch((error) => {
      logger.error(`Final error loading locale ${fullLocale}:`, error);
      throw error;
    });
};

/**
 * Sets the locale if it is present in the supported locales list.
 * Maps 'ua' to 'uk' for compatibility.
 * @param locale - The locale code to check and set.
 * @returns A promise that resolves if the locale is successfully set.
 */

const setLocaleIfPresent = (locale: string): Promise<void> => {
  if (locale === "ua") {
    locale = "uk";
  }

  if (locale === "jp") {
    locale = "ja";
  }

  if (locale === "kz") {
    locale = "kk";
  }

  const availableLocale = (state.get() as TState)?.options?.customization
    ?.available_locales;
  const fullLocale = getFullLocale(locale);

  if (!fullLocale || !SUPPORTED_LOCALES.includes(fullLocale)) {
    logger.error(`Unsupported locale`, {
      locale,
    });

    return Promise.reject(new Error("Unsupported locale"));
  }

  if (availableLocale && availableLocale?.length > 0) {
    localesConfig = setLanguageConfig(locale, availableLocale);
  }

  return setLocale(locale).then(() => {
    isLocaleSet = true;
  });
};

/**
 * Retrieves the browser's preferred language.
 * @returns The browser language or null if not available.
 */
const getBrowserLocale = (): string | null => {
  if (typeof window === "undefined") {
    return null;
  }

  try {
    return window.navigator.language || (window.navigator as any).userLanguage;
  } catch (error) {
    console.error("Error retrieving browser locale:", error);
    logger.error(`Error retrieving browser locale:`, {
      error,
    });

    return null;
  }
};

/**
 * Determines the fallback locale to use, based on the browser's language preference or defaults to 'en'.
 * @returns The selected fallback locale.
 */
const getFallbackLocale = (): string => {
  const browserLocale = getBrowserLocale();
  const localeBase = browserLocale?.split("-")[0];
  const fullLocale = localeBase
    ? PRIMARY_DIALECTS[localeBase as TPrimaryDialect]
    : null;

  if (!fullLocale || !SUPPORTED_LOCALES.includes(fullLocale)) {
    return DEFAULT_LOCALE;
  }

  return fullLocale;
};

/**
 * Initializes the locale by setting it to the fallback locale.
 * @returns A promise that resolves when the locale is loaded.
 */
export const initializeLocale = async (): Promise<void> => {
  clearData();

  if (localesConfig && localesConfig.length > 0 && isLocaleSet) {
    return Promise.resolve();
  }

  localesConfig = setLanguageConfig();
  return await setLocale(getFallbackLocale());
};

/**
 * Returns the currently set locale.
 * @returns The current locale.
 */
const getLocale = (): string => {
  return currentLocale?.split("-")[0];
};

/**
 * Returns the currently set country code.
 * @returns The current country code.
 */
const getCountryCode = (): string => {
  return currentLocale?.split("-")[1];
};

/**
 * Returns the currently set locale.
 * @returns The current full locale.
 */
const getFullLocale = (locale: string) => {
  return (
    SUPPORTED_LOCALES.find((item: string) => item.split("-")[0] === locale) ||
    PRIMARY_DIALECTS[locale as TPrimaryDialect] ||
    locale
  );
};

/**
 * Checks if the locale has been set.
 * @returns True if the locale is set, otherwise false.
 */
const getIsLocaleSet = (): boolean => {
  return isLocaleSet;
};

/**
 * Gets the localized message for the current locale.
 * Falls back to a default message if not found.
 * @param param0 - An object containing the following properties:
 * - message: The key for the message to retrieve.
 * - children (optional): Fallback content if the message is not found.
 * @returns The localized message or fallback.
 */
const getMessage = ({
  message,
  children,
}: {
  message: string;
  children?: string | Preact.PreactDOMAttributes;
}): string => {
  const locale = cachedLocales[currentLocale];
  const fallback = children || message || "i18n error";
  const content =
    message && locale && locale[message] ? locale[message] : fallback;

  return content?.toString();
};

const normalizeLocale = (locale: string): string => {
  if (locale === "jp") return "ja";
  if (locale === "kz") return "kk";
  if (locale === "ua") return "uk";
  return locale;
};

/**
 * Generates language configurations with corresponding country flags.
 * Imports only necessary icons for the supported locales.
 * @returns A promise that resolves to an array of language configurations.
 */
const setLanguageConfig = (
  locale?: string,
  availableLocale?: string[] | null,
): ILocaleConfig[] => {
  if (locale) {
    locale = normalizeLocale(locale);
  }
  if (locale && availableLocale && !availableLocale.includes(locale)) {
    availableLocale.unshift(locale);
  }

  if (availableLocale && availableLocale.length > 0) {
    availableLocale = availableLocale.map(normalizeLocale);
  }

  const localesToUse =
    availableLocale && availableLocale.length > 0
      ? availableLocale
      : SUPPORTED_LOCALES;

  const uniqueLocales = new Set<string>();

  return localesToUse
    .map((locale) => {
      const fullLocale =
        SUPPORTED_LOCALES?.find((supportedLocale) =>
          supportedLocale.startsWith(locale),
        ) || locale;

      if (uniqueLocales.has(fullLocale)) return null;
      uniqueLocales.add(fullLocale);

      const [localeCode, countryCode] = fullLocale.split("-");
      const title =
        LANGUAGE_NAMES[fullLocale as keyof typeof LANGUAGE_NAMES]?.[1] ||
        localeCode;

      const url = `/flags/${countryCode?.toLowerCase() || localeCode.toLowerCase()}.svg`;

      return { locale: localeCode, url, title };
    })
    .filter((item): item is ILocaleConfig => item !== null);
};

/**
 * Returns the config with icons and titles.
 * @returns The current locale.
 */
const getLanguageConfig = (): ILocaleConfig[] => {
  return localesConfig;
};

const clearData = () => {
  currentLocale = DEFAULT_LOCALE;
  isLocaleSet = false;
  localesConfig = [];
  cachedLocales = {};
};

export default {
  setLocaleIfPresent,
  getLocale,
  getIsLocaleSet,
  getMessage,
  getLanguageConfig,
  getCountryCode,
  initializeLocale,
  getBrowserLocale,
};
