/* eslint-disable no-use-before-define */
import { ThemeKeys } from "../constants/themeConstants";
import { themes } from "../themes/commons/configs/themes";

const colorSchemes = {
  [ThemeKeys.N1]: "blackGreen",
  [ThemeKeys.N1_NONAME]: "blackGreen",
  [ThemeKeys.SETTLEPAY]: "purple",
  [ThemeKeys.SETTLEPAY_NONAME]: "purple",
  [ThemeKeys.KOMBINE]: "darkBlue",
  [ThemeKeys.PM]: "pm",
};

const logos = {
  [ThemeKeys.N1]: "/brands/n1.png",
  [ThemeKeys.N1_NONAME]: "",
  [ThemeKeys.MUNZEN]: "/brands/munzenLogo.png",
  [ThemeKeys.SETTLEPAY]: "/brands/settlepayLogo.png",
  [ThemeKeys.SETTLEPAY_NONAME]: "",
};

const favicons = {
  [ThemeKeys.N1]: "/brands/n1_favicon.png",
  [ThemeKeys.SETTLEPAY]: "/brands/settlepayFavicon.png",
};

const getFaviconElement = () => {
  if (typeof document !== "undefined") {
    let link = document?.querySelector("link[rel~='icon']");

    if (!link) {
      link = document?.createElement("link");
      link.rel = "icon";
      document?.getElementsByTagName("head")[0].appendChild(link);
    }

    return link;
  }

  return null;
};

const setFaviconHref = (href) => {
  const faviconElement = getFaviconElement();

  faviconElement.href = href;
};

const setTitle = (title) => {
  if (typeof document !== "undefined") {
    document.title = title;
  }
};

export const getThemeConfig = (themeName) => {
  return themes[themeName] || themes[ThemeKeys.DEFAULT];
};

export const getColorScheme = (themeName) => {
  return colorSchemes[themeName];
};

export const getLogo = (themeName) => {
  return logos[themeName];
};

export const getTitle = (themeName, brandData) => {
  return themes?.[themeName]?.title || brandData?.brand_name || "Payment";
};

export const loadFontByUrl = (url) => {
  if (typeof document !== "undefined") {
    // Check if a link with the same href already exists
    let existingLink = document?.querySelector(`link[href="${url}"]`);

    if (!existingLink) {
      let link = document?.createElement("link");
      link.href = url;
      link.rel = "stylesheet";
      document?.head.appendChild(link);
    }
  }
};

export default {
  getStyle: async (themeName, sessionId, signature, brandData) => {
    themeName = themeName || "default";

    const currentThemeName = themeName;
    let faviconHref;

    themeName = themeName || "default";

    if (currentThemeName?.includes("noname") || !currentThemeName) {
      faviconHref = "";
    } else if (favicons[themeName]) {
      faviconHref = favicons[themeName];
    } else if (sessionId && signature) {
      faviconHref = `/api/brand/favicon/${sessionId}/${signature}`;
    }

    const title = getTitle(themeName, brandData);

    try {
      setFaviconHref(faviconHref);
      setTitle(title);

      return {
        themeName,
        faviconHref,
        title,
      };
    } catch (err) {
      return console.log(err);
    }
  },
  getThemeConfig,
  getColorScheme,
  getLogo,
  loadFontByUrl,
};
