import defaultThemeConfig from "../commons/configs/defaultConfig";
import { ThemeConfig } from "../commons/interfaces/configInterface";

const pmThemeConfig: ThemeConfig = {
  ...defaultThemeConfig,
  showDateTooltip: false,
  showCardIcon: false,
  hiddenPaymentDetails: true,
  hiddenHeader: true,
  hasModalAlertForClearCardInfo: false,
  getFieldOrder: ({ field, index }) => {
    if (field.name === "cardNumber") {
      return 0;
    }

    if (field.name === "cardExpire") {
      return 1;
    }

    if (field.name === "cardCvv") {
      return 2;
    }

    if (field.name === "cardHolderName") {
      return 3;
    }

    return index;
  },
};

export default pmThemeConfig;
