interface IOperatorData {
  operator: string;
  ui_code: string;
  currency: string;
  country: string;
  prefix: string;
  regexp: RegExp;
}

export const OPERATOR_DATA: IOperatorData[] = [
  {
    operator: "MTN",
    ui_code: "mtn",
    currency: "GHS",
    country: "Ghana",
    prefix: "+233",
    regexp: /^(\+?233)\d{9}$/,
  },
  {
    operator: "MTN",
    ui_code: "mtn",
    currency: "UGX",
    country: "Uganda",
    prefix: "+256",
    regexp: /^(\+?256)\d{9}$/,
  },
  {
    operator: "MTN",
    ui_code: "mtn",
    currency: "XAF",
    country: "Central Africa",
    prefix: "+",
    regexp: /^(\+?237|\+?241)\d{8,9}$/,
  },
  {
    operator: "MTN",
    ui_code: "mtn",
    currency: "XOF",
    country: "West Africa",
    prefix: "+",
    regexp: /^(\+?225|\+?226|\+?229)\d{8,9}$/,
  },
  {
    operator: "Airtel",
    ui_code: "airtel",
    currency: "GHS",
    country: "Ghana",
    prefix: "+233",
    regexp: /^(\+?233)\d{9}$/,
  },
  {
    operator: "Airtel",
    ui_code: "airtel",
    currency: "UGX",
    country: "Uganda",
    prefix: "+256",
    regexp: /^(\+?256)\d{9}$/,
  },
  {
    operator: "Airtel",
    ui_code: "airtel",
    currency: "KES",
    country: "Kenya",
    prefix: "+254",
    regexp: /^(\+?254)\d{9}$/,
  },
  {
    operator: "Orange",
    ui_code: "orange",
    currency: "XAF",
    country: "Central Africa",
    prefix: "+",
    regexp: /^(\+?237|\+?242|\+?241|\+?236)\d{8,9}$/,
  },
  {
    operator: "Orange",
    ui_code: "orange",
    currency: "XOF",
    country: "West Africa",
    prefix: "+",
    regexp: /^(\+?225|\+?221|\+?223|\+?226|\+?224)\d{8,9}$/,
  },
  {
    operator: "Mpesa",
    ui_code: "mpesa",
    currency: "RWF",
    country: "Rwanda",
    prefix: "+250",
    regexp: /^(\+?250)\d{9}$/,
  },
  {
    operator: "Mpesa",
    ui_code: "mpesa",
    currency: "KES",
    country: "Kenya",
    prefix: "+254",
    regexp: /^(\+?254)\d{9}$/,
  },
  {
    operator: "Vodafone",
    ui_code: "vodafone",
    currency: "GHS",
    country: "Ghana",
    prefix: "+233",
    regexp: /^(\+?233)\d{9}$/,
  },
  {
    operator: "Tigo",
    ui_code: "tigo",
    currency: "GHS",
    country: "Ghana",
    prefix: "+233",
    regexp: /^(\+?233)\d{9}$/,
  },
  {
    operator: "Equitel",
    ui_code: "equitel",
    currency: "KES",
    country: "Kenya",
    prefix: "+254",
    regexp: /^(\+?254)\d{9}$/,
  },
  {
    operator: "e-money",
    ui_code: "e-money",
    currency: "XOF",
    country: "Francophone",
    prefix: "+",
    regexp: /^(\+?225|\+?221)\d{8,9}$/,
  },
  {
    operator: "Free Money",
    ui_code: "free_money",
    currency: "XOF",
    country: "Francophone",
    prefix: "+",
    regexp: /^(\+?225|\+?221)\d{8,9}$/,
  },
  {
    operator: "Wave",
    ui_code: "wave",
    currency: "XOF",
    country: "Francophone",
    prefix: "+",
    regexp: /^(\+?225|\+?221)\d{8,9}$/,
  },
  {
    operator: "Mobi Cash",
    ui_code: "mobi_cash",
    currency: "XOF",
    country: "Francophone",
    prefix: "+225",
    regexp: /^(\+?225)\d{8,9}$/,
  },
  {
    operator: "Moov Money",
    ui_code: "moov_money",
    currency: "XOF",
    country: "Francophone",
    prefix: "+225",
    regexp: /^(\+?225)\d{8,9}$/,
  },
  {
    operator: "Kcell",
    ui_code: "kcell",
    currency: "KZT",
    country: "Kazakhstan",
    prefix: "+7",
    regexp: /^(\+?7)\d{10}$/,
  },
  {
    operator: "Tele2",
    ui_code: "tele2",
    currency: "KZT",
    country: "Kazakhstan",
    prefix: "+7",
    regexp: /^(\+?7)\d{10}$/,
  },
  {
    operator: "Beeline",
    ui_code: "beeline",
    currency: "KZT",
    country: "Kazakhstan",
    prefix: "+7",
    regexp: /^(\+?7)\d{10}$/,
  },
  {
    operator: "Activ",
    ui_code: "activ",
    currency: "KZT",
    country: "Kazakhstan",
    prefix: "+7",
    regexp: /^(\+?7)\d{10}$/,
  },
  {
    operator: "Airtel",
    ui_code: "airtel",
    currency: "KZT",
    country: "Kazakhstan",
    prefix: "+7",
    regexp: /^(\+?7)\d{10}$/,
  },
  {
    operator: "AirtelTigo",
    ui_code: "airteltigo",
    currency: "GHS",
    country: "Ghana",
    prefix: "+233",
    regexp: /^\+?[1-9]\d{9,14}$/,
  },
  {
    operator: "HaloPesa",
    ui_code: "halopesa",
    currency: "TZS",
    country: "Tanzania",
    prefix: "+255",
    regexp: /^\+?[1-9]\d{9,14}$/,
  },
  {
    operator: "Airtel",
    ui_code: "airtel",
    currency: "TZS",
    country: "Tanzania",
    prefix: "+255",
    regexp: /^\+?[1-9]\d{9,14}$/,
  },
  {
    operator: "Vodafone",
    ui_code: "vodafone",
    currency: "TZS",
    country: "Tanzania",
    prefix: "+255",
    regexp: /^\+?[1-9]\d{9,14}$/,
  },
  {
    operator: "Tigo",
    ui_code: "tigo",
    currency: "TZS",
    country: "Tanzania",
    prefix: "+255",
    regexp: /^\+?[1-9]\d{9,14}$/,
  },
  {
    operator: "Tmoney",
    ui_code: "t money",
    currency: "XOF",
    country: "Togo",
    prefix: "+228",
    regexp: /^\+?[1-9]\d{9,14}$/,
  },
];
