/* eslint-disable no-use-before-define */
import api, { notificationError } from "./api";
import logger from "./logger";
import tracker from "./tracker";
import i18n from "./i18n";
import { HTTP_ERRORS, STATUS_TO_ROUTER_MAP } from "./constants";
import EventEmitter from "events";
import testData from "./testData";
import { deepEqual } from "./deepEqual";
import { debounce, getSessionFromUrl } from "./utils";

const events = new EventEmitter();
const initialSessionData = getSessionFromUrl();

let state = {};
let session = {
  type: initialSessionData.type,
  sessionId: initialSessionData.sessionId,
  signature: initialSessionData.signature,
};

let screenWidth = "";
let screenHeight = "";
let windowWidth = "";
let windowHeight = "";
let screenColorDepth = "";
let screenPixelDepth = "";
let javaEnabled = "";
let userAgent = "";
let language = "";

const date = new Date();
const currentTimeZoneOffsetInMin = date.getTimezoneOffset();
const conversionRequestTimeout = {
  id: null,
  interval: 2 * 1000,
};

if (typeof window !== "undefined") {
  screenWidth = window.screen.width;
  screenHeight = window.screen.height;
  windowWidth = window.innerWidth;
  windowHeight = window.innerHeight;
  screenColorDepth = window.screen.colorDepth;
  screenPixelDepth = window.screen.pixelDepth;
  javaEnabled = window.navigator.javaEnabled();
  userAgent = navigator.userAgent;
  language = navigator.language;
}

const getRouteForSession = (route) => {
  const { type, sessionId, signature } = session;
  const pathArray = [type, sessionId, signature];
  const hasMissingParts = !type || !sessionId || !signature;
  let actualRoute = null;

  if (route === "error" && hasMissingParts) {
    actualRoute = "/";
  } else {
    if (route !== "main") {
      pathArray.push(route);
    }

    actualRoute = `/${pathArray.join("/")}`;
  }

  return actualRoute;
};

export const changeLocale = (locale) => {
  if (isTestMode()) {
    testData.set(locale, ["options", "locale"]);
  }

  return i18n.setLocaleIfPresent(locale);
};

const changeWindowLocation = (newLocation) => {
  if (typeof window !== "undefined") {
    if (typeof window.location?.replace === "function") {
      window.location.replace(newLocation);
    } else {
      window.location.href = newLocation;
    }

    setTimeout(() => {
      window.isRedirecting = false;
    }, 1000);
  }
};

const changeLocation = (newLocation, forTop) => {
  if (typeof window !== "undefined") {
    if (forTop && typeof top !== "undefined") {
      try {
        if (typeof top.location?.replace === "function") {
          top.location.replace(newLocation);
        } else {
          top.location.href = newLocation;
        }
      } catch (error) {
        console.error(
          "Unable to access top.location, falling back to window.location",
          error,
        );

        logger.error(
          "Unable to access top.location, falling back to window.location",
          {
            error,
          },
        );

        changeWindowLocation(newLocation);
      }
    } else {
      changeWindowLocation(newLocation);
    }
  }
};

const handleChangeLocation = debounce((url) => {
  console.log("Redirect to:", url);

  changeLocation(url);
}, 500);

const handleChallengeStatus = ({ status, challenge }) => {
  if (!challenge || !challenge.type) {
    logger.error("No proper challenge data for challenge status!", {
      status,
      challenge,
    });

    return { name: fallbackRoute, url: getRouteForSession(fallbackRoute) };
  }

  tracker.track("challenge_accepted");

  if (challenge.type === "redirect" && !window.isRedirecting) {
    window.isRedirecting = true;

    handleChangeLocation(challenge.url);
  }
};

const getRouteFromState = ({ status, challenge }) => {
  if (!status) return { name: "main", url: getRouteForSession("main") };

  const fallbackRoute = "error";

  const statusEffects = {
    challenge: handleChallengeStatus,
  };

  if (typeof statusEffects[status] === "function") {
    try {
      statusEffects[status]({ status, challenge });
    } catch (error) {
      logger.error("Failed to run effects for status", { status });
    }
  }

  const properRoute = STATUS_TO_ROUTER_MAP[status];

  if (!properRoute) {
    logger.error("No proper route mapped from status!", { status });

    return { name: fallbackRoute, url: getRouteForSession(fallbackRoute) };
  }

  return { name: properRoute, url: getRouteForSession(properRoute) };
};

const setState = (newState) => {
  const keysToDeleteIfAbsentInNewState = ["virtual_account_request"];

  delete state.actualRoute;
  delete newState.actualRoute;

  if (deepEqual(state, newState)) {
    state.actualRoute = getRouteFromState(state);

    return state;
  }

  state = { ...state, ...newState };

  state.actualRoute = getRouteFromState(state);

  keysToDeleteIfAbsentInNewState.forEach((key) => {
    if (state[key] && !newState[key] && newState.virtual_account) {
      delete state[key];
    }
  });

  events.emit("dataUpdated", state);

  if (
    state.apple_pay &&
    state.apple_pay.session &&
    state.apple_pay.session.data
  ) {
    events.emit("applePaySessionReceived", state.apple_pay.session.data);
  }

  if (state?.options && !state?.options?.card) {
    state.options.card = {
      enabled_remember_me: true,
      enabled_apple_pay: false,
      enabled_google_pay: false,
    };
  }

  return state;
};

const getState = () => {
  return state;
};

const getRequisitesBySelectedMethodId = (selectedMethodId) => {
  const currentState = getState();

  const { requisites } = currentState;
  const requisitesData = requisites && requisites.length ? requisites : [];

  const findSelectedItem = (items) =>
    items.find((item) => {
      return item.payment_method_id
        ? item.payment_method_id === selectedMethodId
        : true;
    });

  const selectedRequisites = findSelectedItem(requisitesData);

  return selectedRequisites;
};

const resetState = () => {
  state = {};

  return state;
};

const setSession = ({ type, sessionId, signature }) => {
  session = { ...session, ...{ type, sessionId, signature } };

  return session;
};

const getSession = () => {
  return session;
};

const getPixelData = () => ({
  user_agent: userAgent,
  language,
  screen_width: screenWidth,
  screen_height: screenHeight,
  window_width: windowWidth,
  window_height: windowHeight,
  screen_color_depth: screenColorDepth,
  screen_pixel_depth: screenPixelDepth,
  timezone_offset_min: currentTimeZoneOffsetInMin,
  java_enabled: javaEnabled,
  js_enabled: true,
});

const getTheme = () => {
  return state?.options?.theme;
};

export const checkNoSuccessDataCases = (data, resolve) => {
  if (data.isIdle) {
    return resolve(state);
  }

  if (data.isUnauthorized) {
    setState({
      status: "error",
      error: { isUnauthorized: true, ...HTTP_ERRORS["403"] },
    });

    return resolve({
      ...state,
      isUnauthorized: true,
    });
  }

  if (data.isError) {
    if (state.status === "opened") {
      return resolve(state);
    }

    return resolve(
      setState({
        status: "error",
        error: data,
      }),
    );
  }
};

export const setLoggerData = (data) => {
  if (data.customer) {
    logger.setUser({ id: data.customer.identifier });
  }

  logger.addLabels({
    "session.sessionId": session.sessionId,
    "session.signature": session.signature,
    "widget.selectedMethodId": data.selected_method_id,
    "widget.theme": data.options?.theme,
    requestId: data?.request_id,
    reference: data?.reference,
    amount: data?.amount,
    currency: data?.currency,
    "widget.cardRememberMe": data.options?.enabled_remember_me,
    "widget.cardApplePay": data.options?.enabled_apple_pay,
    "widget.cardGooglePay": data.options?.enabled_google_pay,
    "widget.locale": data.options?.locale,
  });
};

const trackOpen = () => {
  const track = tracker
    .track("open", {
      pixel: getPixelData(),
    })
    .then((res) => {
      if (res?.status === "error") {
        setState({
          status: "error",
          error: {
            ...HTTP_ERRORS[res?.errorData?.response?.status],
            isError: true,
          },
        });
      }
    });

  return track.catch(() => {
    trackOpen();
  });
};

const trackMethodSelected = ({ id }) =>
  tracker
    .track("payment_method_selected", {
      selected_method_id: id,
    })
    .then((res) => {
      if (
        res?.status === "error" ||
        (res?.errorData &&
          (res.errorData.code === "ERR_NETWORK" ||
            res.errorData.status !== 200))
      ) {
        notificationError({
          error: res?.errorData || res,
          reason: "selectedMethodError",
        });

        if (res?.errorData && res.errorData.code === "ERR_NETWORK") {
          setState({ pressedBackToMethod: !id });
        }
      } else {
        setState({ selected_method_id: id });
      }

      return res;
    });

const onPaymentMethodSelected = ({ id }) => {
  const currentState = getState();

  setLoading({
    status: true,
    eventName: "select_method",
    withBlur: currentState?.available_methods?.length > 1,
  });

  return trackMethodSelected({ id }).finally(() => {
    if (currentState?.available_methods?.length === 1) {
      setTimeout(() => {
        setLoading({ status: false, eventName: "select_method" });
      }, 0);
    } else {
      setLoading({ status: false, eventName: "select_method" });
    }
  });
};

const getAvailablePaymentMethods = () => {
  const { available_methods = [], options } = getState();
  const methods = isTestMode()
    ? testData.supportedPaymentMethods
    : available_methods;

  return methods.filter((item) =>
    // filter only available_methods with item.method === options.method or leave all for auto, apple_pay, google_pay
    ["auto", "apple_pay", "google_pay"]?.includes(options?.method)
      ? true
      : item.method === options?.method,
  );
};

const hasPaymentMethodsToShow = () => {
  const { status, selected_method_id } = getState();
  const methods = getAvailablePaymentMethods();

  return (
    ["opened", "failed_retry"].includes(status) &&
    selected_method_id &&
    methods.length > 1
  );
};

const getSelectedPaymentMethod = () => {
  const { selected_method_id } = getState();
  const methods = getAvailablePaymentMethods();

  const result =
    selected_method_id && typeof methods.find === "function"
      ? methods.find((item) => {
          return item?.id === selected_method_id;
        })
      : null;

  return result;
};

const getAndSaveConversion = (data, { isInverted }) => {
  let result;

  const getConversion = () => {
    return api.getConversion(data, getSession());
  };

  const saveConversion = ({
    conversion_currency,
    conversion_amount,
    pay_amount,
    pay_currency,
    rate,
    rate_date,
  }) => {
    const newValue = {
      cryptoConverted: isInverted
        ? {
            pay_currency: conversion_currency,
            conversion_currency: pay_currency,
            conversion_amount: pay_amount,
            pay_amount: conversion_amount,
            rate: 1 / rate,
            rate_date,
          }
        : {
            conversion_currency,
            conversion_amount,
            pay_amount,
            pay_currency,
            rate,
            rate_date,
          },
    };

    setState(newValue);

    return newValue;
  };

  clearTimeout(conversionRequestTimeout.id);

  result = getConversion().then((response) => {
    saveConversion(response.data || response);

    return response;
  });

  return result;
};

const setCurrentSession = ({ type, sessionId, signature, resolve }) => {
  if (!type || !sessionId || !signature) {
    return resolve(
      setState({
        status: "error",
        error: { ...HTTP_ERRORS["404"] },
      }),
    );
  }
};

export const expiredStatus = () => {
  setState({
    error: {
      title: "sessionExpired",
      description: "sessionExpiredDesc",
    },
  });
};

const isPareq = () => {
  const currentState = getState();
  const { status, challenge } = currentState;
  const { type, url, pareq, md, term_url } = challenge || {};
  const hasPareqType = type === "three_ds_pareq_form";
  const hasMissingField = !url || !pareq || !md || !term_url;

  if (!["challenge", "challenge_accepted"].includes(status)) {
    return false;
  }

  if (hasPareqType && hasMissingField) {
    logger.error("No required fields passed in challenge object", {
      status,
      challenge,
    });
  }

  return hasPareqType;
};

const isCreq = () => {
  const currentState = getState();
  const { status, challenge } = currentState;
  const { type, url, creq } = challenge || {};
  const hasCreqType = type === "three_ds_creq_form";
  const hasMissingField = !url || !creq;

  if (!["challenge", "challenge_accepted"].includes(status)) {
    return false;
  }

  if (hasCreqType && hasMissingField) {
    logger.error("No required fields passed in challenge object", {
      status,
      challenge,
    });
  }

  return hasCreqType;
};

const isAutoSubmit = () => {
  const currentState = getState();
  const { status, challenge } = currentState;
  const { type, url } = challenge || {};
  const hasAutoSubmitType = type === "three_ds_auto_submit_form";
  const hasMissingField = !url;

  if (!["challenge", "challenge_accepted"].includes(status)) {
    return false;
  }

  if (hasAutoSubmitType && hasMissingField) {
    logger.error("No required fields passed in challenge object", {
      status,
      challenge,
    });
  }

  return hasAutoSubmitType;
};

const whenPaymentResolved = () => {
  return new Promise((resolve) => {
    const onDataUpdated = () => {
      const { status } = state;
      const resolvedPaymentStatuses = {
        success: true,
        failed: false,
        failed_retry: false,
        challenge: true,
      };

      logger.log("State when payment Resolved", {
        status,
        resolved: resolvedPaymentStatuses[status],
      });

      if (Object.keys(resolvedPaymentStatuses).includes(status)) {
        resolve(resolvedPaymentStatuses[status]);
        events.off("dataUpdated", onDataUpdated);
      }
    };

    events.on("dataUpdated", onDataUpdated);
  });
};

const clearAppleMerchantSession = () => {
  if (
    state.apple_pay &&
    state.apple_pay.session &&
    !state.apple_pay.session.data
  ) {
    return;
  }

  setState({
    apple_pay: {
      ...state.apple_pay,
      session: {
        ...state.apple_pay.session,
        data: null,
      },
    },
  });
};

const redirectToPending = () => {
  setState({
    status: "form_submitted",
  });
};

export const getConnectionStatus = () => {
  return state.hasConnection;
};
export const setConnectionStatus = (status) => {
  setState({
    hasConnection: status,
  });
};

export const isTestMode = () => {
  const { sessionId, signature } = getSession();

  return testData.isTestMode(sessionId, signature);
};

export const setLoading = ({
  eventName = "",
  status = false,
  withBlur = false,
  text = "",
  extra = null,
}) => {
  const state = getState();

  const currentLoadingEvents = {
    ...(state.loadingData?.loadingEvents || {}),
    [eventName]: {
      name: eventName,
      status,
      withBlurStatus: withBlur,
      text,
      extra,
    },
  };

  setState({
    loadingData: {
      status: Object.values(currentLoadingEvents).some((item) => item.status),
      hasBlur: Object.values(currentLoadingEvents).some(
        (item) => item.status && item.withBlurStatus,
      ),
      loadingEvents: currentLoadingEvents,
      text: Object.values(currentLoadingEvents).find(
        (item) => item.status && item.text,
      )?.text,
      extra:
        Object.values(currentLoadingEvents).find(
          (item) => item.status && item.extra,
        )?.extra || extra,
    },
  });
};

const setDeviceFingerprint = (fingerprint) => {
  state = { ...state, deviceFingerprint: fingerprint };
};

const getDeviceFingerprint = () => {
  return state.deviceFingerprint;
};

const isInIframe = () => {
  if (window && typeof window !== "undefined") {
    return window.self !== window.top;
  }

  return false;
};

export default {
  events,
  get: getState,
  set: setState,
  reset: resetState,
  setSession,
  getSession,
  getRouteForSession,
  isPareq,
  isCreq,
  isAutoSubmit,
  getTheme,
  getPixelData,
  whenPaymentResolved,
  clearAppleMerchantSession,
  redirectToPending,
  changeWindowLocation,
  changeLocation,
  setLoggerData,
  trackOpen,
  onPaymentMethodSelected,
  getAvailablePaymentMethods,
  getRequisitesBySelectedMethodId,
  hasPaymentMethodsToShow,
  getSelectedPaymentMethod,
  getAndSaveConversion,
  setCurrentSession,
  expiredStatus,
  checkNoSuccessDataCases,
  changeLocale,
  isTestMode,
  setLoading,
  setDeviceFingerprint,
  getDeviceFingerprint,
  isInIframe,
};
