import { ThemeKeys } from "../constants/themeConstants";
import challengeData from "./testDataChallengeOptions";
import {
  AUTO_PROCESSED_PAYMENT_METHODS,
  PRE_PROCESSED_PAYMENT_METHODS,
} from "../constants/paymentMethods";
import brand from "./brand";
import i18n from "./i18n";
import availablePaymentMethods from "./availablePaymentMethodsForTest";
import supportedPaymentMethods from "./supportedPaymentMethodsForTest";

const LOCAL_STORAGE_KEY = "testData";

const challenge = {
  // ONLY FOR challenge status
  type: "redirect", // redirect, three_ds_pareq_form, three_ds_creq_form, qr,br, three_ds_auto_submit_form, otp, card_pin, address_verification, phone_verification,  bank_transfer_africa
  url: "https://google.com",
  pareq: "abcdefgpareq",
  md: "abcdefgmd",
  term_url: "https://checkout.asquad.io/termUrl",
  creq: "abcdefgcreq",
  http_method: "value", // optional one of 'GET, HEAD, POST, PUT, PATCH, DELETE, OPTIONS, TRACE'
  fields: { foo: "bar" }, //required for type three_ds_auto_submit_form
  bank_transfer_africa: {},
  bank_account: {},
  wallet_m10: {},
  p2c: {},
  account_requisites: {},
};

const paymentData = {
  request_id: "xxxxx",
  identifier: "HPI0000000053DN4",
  status: "new", // new | opened | form_submitted | challenge | challenge_accepted | challenge_succeeded | success | failed | failed_retry | expired
  reference: "order-of-merchant-0001", // required
  currency: "UAH", // required
  amount: "10000.23", // required, bigdecimal
  description: "trade pay test ", // optional, string
  selected_method_id: null, // method uuid | null
  selected_method_channel: null, // method channel | null
  customer: {
    // optional
    identifier: "customer-of-merchant-00034", // required
    birthday: "2019-08-24", // optional
    email: "user@example.com", // optional, left it commented for preview test mode
    first_name: "Dart", // optional
    gender: "female", // optional
    ip: "192.168.0.1", // optional
    last_name: "Vader", // optional
    phone: "+38099999999",
    country: "", // optional, ISO 3166-1
    // state_code: "30", // optional, ISO 3166-2
    // city: "Kyiv", // optional
    // address: "Stepana street, 9", // optional
    // zip_code: "38082365", // optional
    itn: "", // optional
  },
  wallet: {
    number: "9379992",
    verification_code: "",
  },
  virtual_account_request: {
    customer_bank_verification_number: "12345678",
  },
  // mobile_money: {
  //   phone: "+22504123123",
  // },
  wallet_m10: {
    account_number: "30999999999",
  },
  // upi: {
  //   account: "",
  // },
  options: {
    method: "auto",
    card: {
      enabled_remember_me: true, // bool, optional
      enabled_apple_pay: false, // bool, optional
      enabled_google_pay: false, // bool, optional
    },
    theme: "default", // string, optional // kombine, default, PM_brand
    locale: "en", // enum, optional
    redirect_url: "https://redirect.url", // string, url, required
    cancel_url: "https://cancel.url", // string, url, required
    status_page: {
      enabled: true, // bool, required
      redirect_after_sec: null, // int in seconds, optional
    }, // required
    crypto_calculation_enabled: false,
    min_notification_enabled: false,
  },
  cards: [
    {
      identifier: "3",
      bin: "980440", // первая часть маски карты, после - звездочки, на данный момент - всегда 6 цифр
      last4: "5556", // последняя часть маски карты, перед - звездочки, всегда 4 цифры
      brand: "prostir", // идентификатор ПС для отображения иконки, enum: mastercard | visa | amex | discover | jcb | unionpay | diners_club | maestro (на данный момент используем только первые 2)
      exp_month: "01", // месяц для заполнения экспайр даты, всегда 2 цифры
      exp_year: "2028", // год для заполнения эксайр даты, всегда 4 цифры
      token: "card_identifier_on_hpp3", // основная информация, передаваемая для платежа сохраненной картой
      enabled: true, // фильтруем массив и не отображаем карты со значением false
      holder_name: "Taras Shevchenko",
    },
    {
      identifier: "2",
      bin: "424242", // первая часть маски карты, после - звездочки, на данный момент - всегда 6 цифр
      last4: "4242", // последняя часть маски карты, перед - звездочки, всегда 4 цифры
      brand: "visa", // идентификатор ПС для отображения иконки, enum: mastercard | visa | amex | discover | jcb | unionpay | diners_club | maestro (на данный момент используем только первые 2)
      exp_month: "12", // месяц для заполнения экспайр даты, всегда 2 цифры
      exp_year: "2024", // год для заполнения эксайр даты, всегда 4 цифры
      token: "card_identifier_on_hpp", // основная информация, передаваемая для платежа сохраненной картой
      enabled: true, // фильтруем массив и не отображаем карты со значением false
      holder_name: "Ivan Franko",
    },
    {
      identifier: "1",
      bin: "521212", // первая часть маски карты, после - звездочки, на данный момент - всегда 6 цифр
      last4: "1212", // последняя часть маски карты, перед - звездочки, всегда 4 цифры
      brand: "mastercard", // идентификатор ПС для отображения иконки, enum: mastercard | visa | amex | discover | jcb | unionpay | diners_club | maestro (на данный момент используем только первые 2)
      exp_month: "01", // месяц для заполнения экспайр даты, всегда 2 цифры
      exp_year: "2026", // год для заполнения эксайр даты, всегда 4 цифры
      token: "card_identifier_on_hpp2", // основная информация, передаваемая для платежа сохраненной картой
      enabled: true, // фильтруем массив и не отображаем карты со значением false
      holder_name: "Vasyl Stus",
    },
  ],
  apple_pay: {
    error: false,
    identity: {
      wait: true,
      data: {
        merchant_identifier: "merchant.io.cardgate",
        country: "UA",
        enabled_3ds: true,
        enabled_credit_card: true,
        enabled_debit_card: true,
        enabled_emv: false,
        supported_networks: ["visa", "mastercard"],
      },
    },
    session: {
      wait: true,
      data: null,
    },
  },
  google_pay: {
    error: false,
    identity: {
      wait: true,
      data: {
        merchant_name: "Brand Test",
        merchant_id: "01415593990397374268",
        country: "UA",
        allowed_auth_methods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
        allowed_card_networks: [
          "AMEX",
          "DISCOVER",
          "INTERAC",
          "JCB",
          "MASTERCARD",
          "MIR",
          "VISA",
        ],
        gateway: "concordpay",
        gateway_merchant_id: "BO_f76e62e9f2386dc",
        environment: "test",
      },
    },
  },
  bank_account: {
    account_number: 123,
    // bank_code: 123,
    // account_dob_day: 12,
    // account_dob_month: 12,
    // // account_dob_year: 2024,
    // bank_verification_number: 12333,
  },
};

const testData = {
  ...paymentData,
  challenge,
};

const virtualAccount = [
  {
    // provider_bank_name: "Wema Bank",
    // name: "KPY-LAN",
    // number: "7590000093",
    error_code: 1934,
    error_reason: null,
  },
  {
    provider_bank_name: "Wema Bank",
    name: "KPY-LAN",
    number: "7590000093",
    error_code: 0,
    error_reason: null,
  },
];

const requisites = [
  {
    payment_method_id: "51b6a7c4-11e0-4fbc-a372-c44df3405623122333",
    deposit_opay: { number: "12345678" },
    error_code: 0,
    error_reason: null,
  },
  {
    payment_method_id: "51b6a7c4-11e0-4fbc-a372-c44df3405623122333pamplay",
    deposit_palmpay: {
      number: "+38012345678",
    },
    error_code: 0,
    error_reason: null,
  },
  {
    payment_method_id: "51b6a7c4-11e0-4fbc-a372-c44df3405623122333pamplayError",
    deposit_palmpay: {},
    error_code: 400,
    error_reason: "Can't recieve number",
  },
];

const cryptoPayment = {
  address: "0x432546f9EBd98d51067848e3A16C501C509D1ddb",
  address_tag: "0x432546f9E34124fvvcv",
  address_qr_code_image:
    "iVBORw0KGgoAAAANSUhEUgAAAMgAAADIAQAAAACFI5MzAAABdklEQVR4Xu2XQW6EMAxFPZoFS47ATeBiSCBxMbhJjpBlFhW//zstU02ny5pWwtKgJG8WyN/+Doafwp4PjrjIRRR/gyQzu6Pc8GbN2s7ctdGEvyWJdA1XwB5OirVOltTsXFl/CkGz8d2w4TSSmi1PSdk5g8D1GVghWNsXyv0+UY2m0kuk/nv1BhAPpQhM0ec+lGg/dzUxwGys1mhCaYBmtwlyCv0nmrA7Zy2WxC6BOjaYwG55NBv4gPJ0ApE/oAz5rhadZZnhZG8/rJrEDSuYUB/2qblhSSn1SzQBbUrRUSmTSMEEJmn8tWzQ6IwnxWxSiibILbtyTIwoou6kKoPGRl+VCibskjzVuc1Ddskxs6II3TJbx4ey45USTTxYqPkOHaaHX0cRCiJVel1kfXgfbhlGIJsC8tjp2qLrQzSpN0ioQqTP0y06ivjw9hQl2sUZpLYKH6N97ZIgAr80rOaWyU+qh5NHEa9R96raouHkdVzkIor/Sd4B0Kift/ogXDIAAAAASUVORK5CYII=",
  address_uri: "ethereum:0x432546f9EBd98d51067848e3A16C501C509D1ddb",
  currency: "UAH",
  network: "matic",
};

if (testData.status === "failed_retry") {
  testData.available_methods = availablePaymentMethods;
  testData.error = {
    error_code: 1024,
    error_reason: "some_reason",
  };
}

const deepUpdate = (original, keys, value) => {
  if (keys.length === 0) {
    return value;
  }

  const currentKey = keys[0];

  if (Array.isArray(original)) {
    return original.map((v, index) =>
      index === currentKey ? deepUpdate(v, keys.slice(1), value) : v,
    );
  } else if (typeof original === "object" && original !== null) {
    return Object.fromEntries(
      Object.entries(original).map((keyValuePair) => {
        const [k, v] = keyValuePair;

        if (k === currentKey) {
          return [k, deepUpdate(v, keys.slice(1), value)];
        }

        return keyValuePair;
      }),
    );
  }

  return original;
};

const getConversion = () => {
  const data = {
    conversion_currency: "EUR",
    conversion_amount: "100.6",
    pay_amount: "9.42", // six symbols after comma
    pay_currency: "MATIC",
    rate: "0.05", // seven symbols after comma
    rate_date: "2023-10-12T10:58:47.063076",
    info: {
      waiting_time_minutes: 30,
      amount: {
        min: "1",
        max: "10",
      },
      active: true,
      network_fee: "3",
    },
  };

  return data;
};

const getCryptoData = () => {
  const data = {
    ...cryptoPayment,
    currency_amount: "200.23",
    error_code: 0,
    exchange_allow_calculator: true,
    ...getConversion(),
    locale: "ua",
    //   options: {
    //     theme: "default", // string, optional
    //     locale: "en", // enum, optional
    //     method: "crypto",
    //   },
  };

  return data;
};

const setLocalStorageData = (val) =>
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(val));

const getLocalStorageData = () => {
  try {
    return (
      localStorage.getItem(LOCAL_STORAGE_KEY) &&
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
    );
  } catch (err) {
    console.log(err);
  }
};

const validateData = (data) => data && data.status;

const get = () => {
  const localStorageData = getLocalStorageData();

  if (!localStorageData) {
    setLocalStorageData(testData);

    return testData;
  }

  const validationResult = validateData(localStorageData);

  if (!validationResult) {
    return testData;
  }

  return localStorageData;
};

const reset = () => {
  setLocalStorageData(testData);
};

const set = (value, key) => {
  const storageObjNew = get();

  if (key && Array.isArray(key)) {
    const updatedData = deepUpdate(storageObjNew, key, value);

    return localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedData));
  } else if (key) {
    if (key === "qr") {
      storageObjNew.challenge = {
        ...storageObjNew.challenge,
        qr: value,
      };
    } else {
      storageObjNew[key] = value;
    }

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(storageObjNew));
  } else {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(value));
  }

  return storageObjNew;
};

const updateLocalStorageData = (data) => {
  let localStorageData = getLocalStorageData();

  if (!localStorageData) {
    setLocalStorageData(data);

    return;
  }

  localStorageData = { ...localStorageData };

  Object.entries(data).forEach(([key, value]) => {
    const keys = Array.isArray(data.keys) ? [...data.keys, key] : [key];
    set(value, keys);
    localStorageData[key] = value;
  });

  setLocalStorageData(localStorageData);
};

const isTestMode = (sessionId, signature) =>
  sessionId === "test" && signature === "test";

const setChallengeStateForMethod = (method) => {
  const {
    challengeDataQr,
    challengeDataBrLottery,
    challengeDataBrBoleto,
    challengeDataP2P,
  } = challengeData;

  switch (method) {
    case "upi":
    case "pix":
      updateLocalStorageData(challengeDataQr);
      break;
    case "lottery":
      updateLocalStorageData(challengeDataBrLottery);
      break;
    case "boleto":
      updateLocalStorageData(challengeDataBrBoleto);
      break;
    case "p2p":
      updateLocalStorageData(challengeDataP2P);
      break;
    default:
      updateLocalStorageData({
        status: "challenge",
        challenge: {
          type: "redirect",
          url: "/",
        },
      });
      break;
  }
};

const onPaymentMethodSelected = (id) => {
  set(id, "selected_method_id");
  const selectedMethod = availablePaymentMethods.find(
    (method) => method.id === id,
  );

  if (selectedMethod?.method === "crypto") {
    const crypto = [{ ...cryptoPayment, currency: selectedMethod?.currency }];

    setTimeout(() => {
      set(crypto, "crypto");
      console.log("crypto data received from backend", crypto);
    }, 2000);
  }
};

const handleChallengeOutcome = (method) => {
  if (method === "mobile_money") {
    set("challenge", "status");
    set("stk", ["challenge", "type"]);

    return;
  }

  set("challenge_succeeded", "status");
  setTimeout(() => {
    set(Math.random() < 0.5 ? "success" : "failed", "status");
  }, 2000);
};

const testRequest = (name, data) => {
  if (name === "trackEvent") {
    switch (data?.event) {
      case "payment_method_selected":
        onPaymentMethodSelected(data?.selected_method_id);
        break;
      case "open":
        set("opened", "status");
        set(availablePaymentMethods, "available_methods");
        break;
      case "challenge_accepted":
        set("challenge_accepted", "status");
        break;
    }
  } else if (name === "sendFormData") {
    const method = data.method;

    const methodsWithChallenge = [
      "mobile_money",
      "bank_transfer_argentina",
      "bank_transfer_africa",
      "bank_account_africa",
      "wallet_opay",
      "wallet_opay_qr",
      "wallet_m10",
      "ussd",
      "blik",
      "p2c",
      "pay_code_palmpay",
      "interac_etransfer",
      "pay_code_palmpay",
      "interac_etransfer",
      "bank_transfer_japan",
      "bank_transfer_south_korea",
      "qr_code",
      "bank_account_indonesia",
    ];

    const mobileMoneyMethod = method === "mobile_money";
    const bankTransferArgentinaMethod = method === "bank_transfer_argentina";
    const bankTransferAfricaMethod = method === "bank_transfer_africa";
    const bankAccountAfricaMethod = method === "bank_account_africa";
    const bankAccountIndonesiaMethod = method === "bank_account_indonesia";
    const wallet_opay = method === "wallet_opay";
    const wallet_opayQr = method === "wallet_opay_qr";
    const wallet_m10 = method === "wallet_m10";
    const ussdMethod = method === "ussd";
    const isBlikMethod = method === "blik";
    const p2cMethod = method === "p2c";
    const interacEtransfer = method === "interac_etransfer";
    const palmPayCode = method === "pay_code_palmpay";
    const bankTransferJapan = method === "bank_transfer_japan";
    const bankTransferSouthKorea = method === "bank_transfer_south_korea";
    const random = Math.random();

    if (bankTransferJapan) {
      set("challenge", "status");
      set(
        {
          type: "select_amount",
          select_amount: {
            amounts: [
              { amount: "100000.19" },
              { amount: "100000.20" },
              { amount: "100000.21" },
              { amount: "100000.22" },
              { amount: "100000.26" },
              { amount: "100000.27" },
              { amount: "100000.28" },
            ],
          },
        },
        ["challenge"],
      );
    }

    const expiration_date = new Date();

    if (method === "qr_code") {
      set("challenge", "status");
      set(
        {
          type: "qr",
          qr: {
            expiration_date: expiration_date.setSeconds(
              expiration_date.getSeconds() + 10,
            ),
            code: "00020126890014BR.GOV.BCB.PIX2567api-pix.bancobs2.com.br/spi/v2/e4fa6d50-33b4-47fa-abcf-842f411307ba520400005303986540512.015802BR5906Luxpag6014Belo Horizonte61083038040362070503***63049696",
            image:
              "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADIAQAAAACFI5MzAAACv0lEQVR42u2XPa6lMAyFjVKkgw1EyjbosiXuBvjZAGwpXbYRiQ1AlyK6nmNGeswrpni4mZEuurpC+ZAS7GP7QPy3iz7kQ/4ZEqkptUu1if5MfktEVkESn2k/LG9sTsaNx4qC0CtheccyJ/O2rlGSkrtYh+BP5ndQk5SnkgdCMPaNlYTPuB89NewGaxb+Fp0fE+Qnudf9+5a5HxOBu+zGfrV++66qHxMcOZh3T7hexbWBhqAgyDC5ATDwSrntoUoFKZl6pBoyrF10SNRLQyJ1xXUpjzaPfSbsFhSkuFcxCAPHOlrs41qrIMmsfSXrl4IsUUu8JA2BWKA+Ec5o64RCsQrCvNp97vct5imardQ2aMg+B2xilmgOyoOlr5p7QqTt8dq7jpGoSqHesX5AIh+SFrOkOhDk84d2HhApNdlqJr/FOqXcsIbsS6Kxp+76J8KDClL43YsSt5S7Ig9uSUESZIjMmFl2kK4waQi76crPix1qrkm1YwVBzSV/FnMiBhbSdnd3eUAkJw5jFt0dwbheQkGSvHcrmUGJXFMoKQhn7EO95LzFkct+6+ABgZCLma1HEeMNuuS/ussTUswaiKBEpjFUHH60CpL8jB4Abs3v6H7V3BMSpZVy9NKYEYzg56AgxR9IMtPQ5yEgHnT3nQckuSmKEtd+fwd0U7NqCFyYuAB0PiexjPApCpLEf7U95g9GLmzUrZ0nJBK03IqtuGZj0hHsg7cnKBHLBn7nno0PSOGF61RE1xhrG9JuNQROZ3/bfYG/TrAA5vYUDwhSzRg+0kpP+QIg0pArDLht2MgAt3W0CiIe1hy2wlPAiLWSIgWBe4WoCX3UbPEaIEFDYF1pKqIgGLGR/qi5ZyRi8kg4x8BX89MRyCfma27j7HliDcH3HI5cJ4SW4WFzpyFYiDuCelBtyTVi9BTk88X/If8n+QVMJAQQoBsc3AAAAABJRU5ErkJggg==",
          },
        },
        ["challenge"],
      );
    }

    if (bankAccountIndonesiaMethod) {
      set("challenge", "status");
      set(
        {
          type: "account_requisites",
          account_requisites: {
            account: "4628 9918 1294 3402",
            bank_name: "Privat bank",
            bank_code: "035",
            instruction: true,
            expiration_date: expiration_date.setSeconds(
              expiration_date.getSeconds() + 10,
            ),
          },
        },
        ["challenge"],
      );
    }

    if (bankTransferSouthKorea) {
      set("challenge", "status");
      set(
        {
          type: "three_ds_auto_submit_form",
          url: "/three-ds-auto-submit-form",
          fields: {
            foo: "bar",
          },
        },
        ["challenge"],
      );
      setTimeout(() => {
        set(Math.random() < 0.5 ? "success" : "failed", "status");
      }, 3000);
    }

    if (bankTransferArgentinaMethod) {
      set("challenge", "status");
      set(
        {
          type: "bank_transfer_argentina",
          bank_transfer_argentina: {
            account_number: "1234567890",
            account_number_alias: "Test1",
          },
        },
        ["challenge"],
      );
    }

    if (palmPayCode) {
      set("challenge", "status");
      set(
        {
          type: "pay_code",
          pay_code: {
            code: "077055",
            expiration_date: "2024-06-05T16:58:09.193Z",
          },
        },
        ["challenge"],
      );
    }

    if (mobileMoneyMethod) {
      set("challenge", "status");
      set("otp", ["challenge", "type"]);
      set("mobile_money", ["options", "method"]);
    }

    if (ussdMethod) {
      set("challenge", "status");
      set(
        {
          type: "ussd",
          ussd: {
            code: "*919*000*5195#",
          },
        },
        ["challenge"],
      );
    }

    if (isBlikMethod) {
      set("challenge", "status");
      set(
        {
          type: "zen_fingerprint",
        },
        ["challenge"],
      );
    }

    if (interacEtransfer) {
      set("challenge", "status");
      set(
        {
          type: "interac_etransfer",
          interac_etransfer: {
            name: "INTERAC E-TRANSFER",
            email: "deposit@ieft.ca",
            secret_question: "66694000c997015f17585d36",
            secret_answer: "PS10052",
          },
        },
        ["challenge"],
      );
    }

    if (p2cMethod && random < 0.5) {
      set("challenge", "status");
      set(
        {
          type: "p2c",
          p2c: {
            card_number: "4246001004121582", // required
            bank_name: "PrivatBank", // optional
            card_holder: "Dart Veider Skywolker", // optional
          },
        },
        ["challenge"],
      );
    }

    if (wallet_opay || wallet_opayQr) {
      set("challenge", "status");
      set(challengeData.challengeDataQr.challenge, ["challenge"]);
    }

    if (bankTransferAfricaMethod) {
      const expiration_date = new Date();

      expiration_date.setMinutes(expiration_date.getMinutes() + 3);

      set("challenge", "status");
      set(
        {
          type: "bank_transfer_africa",
          bank_transfer_africa: {
            bank_name: "test bank",
            bank_code: "12345678",
            account_name: "test account",
            account_number: "00000000000",
            expiration_date,
          },
        },
        ["challenge"],
      );
    }

    if ((wallet_m10 && random > 0.5) || wallet_m10) {
      set("challenge", "status");
      set(
        {
          type: "account_requisites",
          account_requisites: {
            account: "4628 9918 1294 3402",
            instruction: true,
          },
        },
        ["challenge"],
      );
    }

    if (bankAccountAfricaMethod) {
      const expiration_date = new Date();

      expiration_date.setMinutes(expiration_date.getMinutes() + 3);

      set("challenge", "status");
      set("pin", ["challenge", "type"]);
    }

    if (
      !PRE_PROCESSED_PAYMENT_METHODS.includes(method) &&
      !methodsWithChallenge.includes(method)
    ) {
      set("form_submitted", "status");
    }

    setTimeout(() => {
      if (AUTO_PROCESSED_PAYMENT_METHODS.includes(method)) {
        setChallengeStateForMethod(method);
      } else if (PRE_PROCESSED_PAYMENT_METHODS.includes(method)) {
        set(virtualAccount, "virtual_account");
        set(requisites, "requisites");
      } else if (methodsWithChallenge.includes(method)) {
        return;
      } else {
        set(Math.random() < 0.5 ? "success" : "failed", "status");
      }
    }, 2000);
  } else if (name === "getCryptoData") {
    console.log("getCryptoData: ", data);

    return Promise.resolve(getCryptoData());
  } else if (name === "getConversion") {
    const conversionRates = {
      BTC: 0.05,
      ETH: 0.01,
    };
    const isInverted = Object.keys(conversionRates).includes(
      data.conversion_currency,
    );
    const rate =
      conversionRates[
        isInverted ? data.conversion_currency : data.pay_currency
      ] || 0.1;
    const conversionResult = {
      conversion_currency: data.conversion_currency,
      conversion_amount: data.conversion_amount,
      pay_amount: data.conversion_amount * (isInverted ? 1 / rate : rate),
      pay_currency: data.pay_currency,
      rate: isInverted ? 1 / rate : rate,
      info: {
        waiting_time_minutes: 30,
        amount: {
          min: "1",
          max: "10",
        },
        active: true,
        network_fee: "3.512344121",
      },
    };

    console.log("getConversion: ", data, conversionResult);

    return Promise.resolve(conversionResult);
  } else if (name === "submitAccountRequisites") {
    setTimeout(() => {
      set(Math.random() < 0.5 ? "success" : "failed", "status");
    }, 7000);
  } else if (name === "setFingerprintZenData") {
    set("challenge", "status");
    set(
      {
        type: "blik_code",
      },
      ["challenge"],
    );
  } else if (name === "getBanksList") {
    return Promise.resolve(challengeData.bankList);
  }

  return Promise.resolve().then(() => console.log(`${name}: `, data));
};

const localDomains = ["http://0.0.0.0:1234", "http://127.0.0.1:1234"];

const environmentsUrl = [...localDomains];

const isIframe = () => {
  try {
    return !!window && window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const subscribePostMessage = (() => {
  let isSubscribed = false;
  // let previousLoaderName = null;

  return () => {
    if (typeof window === "undefined" || isSubscribed) {
      return;
    }

    window.addEventListener("message", (event) => {
      if (
        event.data &&
        (environmentsUrl.includes(event.origin) ||
          event.origin.startsWith("https://admin.") ||
          event.origin.startsWith("http://admin."))
      ) {
        const data = {
          [event.data.keys]: event.data.value,
        };

        if (data.brandInfo) {
          brand.setBrand({
            ...data.brandInfo,
            currentTheme: data.brandInfo.theme,
          });
        }

        if (data.options?.theme) {
          brand.setBrand({
            theme: data.options.theme,
            hpp_background_colour: data.options.background_color,
            hpp_accent_colour: data.options.accent_color,
            logo: data.options.logo,
            hpp_loader_name: data.options.hpp_loader_name,
            hpp_main_font: data.options.hpp_main_font,
            hpp_main_background_colour: data.options.main_background_colour,
          });
        }

        // if (
        //   data.options?.hpp_loader_name &&
        //   data.options.hpp_loader_name !== previousLoaderName
        // ) {
        //   previousLoaderName = data.options.hpp_loader_name;

        //   set("form_submitted", "status");

        //   setTimeout(() => {
        //     set("new", "status");
        //   }, 3000);

        //   return;
        // }

        if (data.options?.locale) {
          i18n.setLocaleIfPresent(data.options.locale);
        }

        updateLocalStorageData(data);

        if (event.data.value.method === "crypto") {
          updateLocalStorageData(getCryptoData());
        } else {
          updateLocalStorageData({
            status: "opened",
            challenge: {
              type: "redirect",
            },
          });
        }
      }
    });

    isSubscribed = true;
  };
})();

if (process.env.NODE_ENV === "development") {
  window.setTheme = (themeName) => {
    if (Object.values(ThemeKeys).indexOf(themeName) >= 0) {
      const res = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
      res.options.theme = themeName;
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(res));
      console.log("new theme is: ", themeName);
    } else {
      console.log(`There is no ${themeName} theme`);
      console.log(Object.values(ThemeKeys));
    }
  };
}

export default {
  get,
  set,
  reset,
  testRequest,
  isTestMode,
  subscribePostMessage,
  isIframe,
  getCryptoData,
  supportedPaymentMethods,
  handleChallengeOutcome,
};
