import { themesComponents } from "../themes/themesIndex";
import connection from "./connection";
import logger from "./logger";

const preloadComponentsList = ["ManageCard", "CardList", "FormHeader"];

export const componentCache = new Map<string, any>();

/**
 * Preloads all components for a given theme and stores them in a cache.
 * @param themeName - The name of the theme to preload components for.
 */
export const preloadThemeComponents = async (themeName: string) => {
  if (!themesComponents[themeName as keyof typeof themesComponents]) {
    themeName = "default";
  }

  const componentNames =
    themesComponents[themeName as keyof typeof themesComponents];

  try {
    const imports = preloadComponentsList.map((component) => {
      const themeComponentPath = componentNames?.includes(component)
        ? themeName
        : "default";

      const currentConnection = connection.getConnectionStatus();

      return import(
        `../themes/${themeComponentPath}/components/${component}/index.tsx`
      )
        .then((module) => {
          componentCache.set(component, module.default);
        })
        .catch((error) => {
          logger.error(
            `Import error for component "${component}" in theme "${themeName}":`,
            { error, currentConnection },
          );
        });
    });

    await Promise.all(imports);
  } catch (error) {
    logger.error(`Error while preloading theme "${themeName}":`, { error });
  }
};

export default { componentCache, preloadThemeComponents };
