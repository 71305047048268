import { OPERATOR_DATA } from "../constants/operator_data";

const mobileMoneys = [
  ...OPERATOR_DATA.map((entry, index) => ({
    id: `generated-${entry.ui_code}-${entry.currency}${index}`,
    method: "mobile_money",
    currency: entry.currency,
    priority: null,
    channel: {
      ui_code: entry.ui_code,
      identifier: `generated-${entry.ui_code}${index}`,
      adapter: "auto_generated",
      priority: null,
    },
  })),
  {
    id: "51b6a7c4-vapm-4fbc-a372-c44df340560w45",
    method: "mobile_money",
    currency: "USD",
    priority: null,
    channel: {
      ui_code: "test_bank",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "51b6a7c4-vapm-4fbc-a372-c44df34056032342w45",
    method: "mobile_money",
    currency: "USD",
    priority: null,
    channel: {
      name: "Wema bank",
      ui_code: "wema_bank",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "51b6a7c4-vapm-4fbc-a372-c44df34056032342w45-zamupay",
    method: "mobile_money",
    currency: "KES",
    priority: null,
    channel: {
      ui_code: "mpesa",
      identifier: `generated-mpesa-`,
      adapter: "mpesa",
      priority: null,
    },
    provider: {
      identifier: "zamupay",
      name: "zamupay",
    },
  },
  {
    id: "51b6a7c4-vapm-4fbc-a372-c44df34056032342w45-margingate",
    method: "mobile_money",
    currency: "KES",
    priority: null,
    channel: {
      ui_code: "mpesa",
      identifier: `generated-mpesa-`,
      adapter: "mpesa",
      priority: null,
    },
    provider: {
      identifier: "margingate",
      name: "margingate",
    },
  },
];

const availablePaymentMethods = [
  ...mobileMoneys,
  {
    id: "e9f5d7c8-74f3-4b9e-8c7d-6f5e8d3a9c6d1234ffsdeeee",
    method: "blik",
    currency: "PZL",
    priority: 10,
    public_fields: [
      {
        name: "privacy_policy_link",
        value: "https://privacy_policy_link.com",
      },
      {
        name: "terms_of_service_link",
        value: "https://terms_of_service_link.com",
      },
      {
        name: "exchange_to",
        value: "USDT",
      },
      {
        name: "policy_by",
        value: "Munzen (Ultiment s.r.o.)",
      },
      {
        name: "exchange_page",
        value: "true",
      },
      {
        name: "hpp_logo_name",
        value: "Munzen",
      },
    ],
  },
  {
    id: "51b6a7c4-11e0-4fbc-a372-c44df340560c",
    method: "card",
    currency: "UAH",
    priority: 1,
    provider: null,
  },
  {
    id: "51b6a7c4-11e0-4fbc-a372-c44df3405601",
    method: "bank_account_africa",
    currency: "UAH",
    priority: 1,
    provider: null,
  },
  {
    id: "51b6a7c4-11e0-4fbc-a372-c44df340560e",
    method: "crypto",
    currency: "BTC",
    priority: null,
    provider: null,
    validation: {
      min_amount: "0.2",
      max_amount: "100.03",
    },
    public_fields: [
      {
        name: "network_fee",
        value: "on_project2",
      },
    ],
  },
  {
    id: "51b6a7c4-11e0-4fbc-a372-c44df340560r",
    method: "crypto",
    currency: "TRX",
    priority: null,
    provider: null,
  },
  {
    id: "112251b6a7c4-11e0-4fbc-a372-c44df340560c-wallet_m10",
    method: "wallet_m10",
    currency: "UAH",
    priority: 1,
    provider: null,
  },
  {
    id: "112251b6a7c4-11e0-4fbc-a372-c44df340560c-wallet_jeton",
    method: "wallet_jeton",
    currency: "EUR",
    priority: 1,
    provider: null,
  },
  {
    id: "112251b6a7c4-11e0-4fbc-a372-c44df340560c-qr_code_inops",
    method: "qr_code",
    currency: "UAH",
    priority: 1,
    provider: {
      identifier: "qr_code_inops",
      name: "inops",
    },
  },
  {
    id: "112251b6a7c4-11e0-4fbc-a372-c44df340560c-qr_code_ops",
    method: "qr_code",
    currency: "UAH",
    priority: 1,
    provider: {
      identifier: "qr_code_ops",
      name: "ops",
    },
  },
  {
    id: "112251b6a7c4-11e0-4fbc-a372-c44df340560c-bank_account_indonesia_inops",
    method: "bank_account_indonesia",
    currency: "UAH",
    priority: 1,
    provider: {
      identifier: "bank_account_indonesia_inops",
      name: "inops",
    },
  },
  {
    id: "112251b6a7c4-11e0-4fbc-a372-c44df340560c-bank_account_indonesia_ops",
    method: "bank_account_indonesia",
    currency: "UAH",
    priority: 1,
    provider: {
      identifier: "bank_account_indonesia_ops",
      name: "ops",
    },
  },
  {
    id: "112251b6a7c4-11e0-4fbc-a372-c44df340560c",
    method: "open_banking",
    currency: "UAH",
    priority: 1,
    provider: null,
  },
  {
    id: "51b6a7c4-11e0-4fbc-a372-c44df340560t",
    method: "p2c",
    priority: 25,
    currency: "UAH",
    provider: null,
  },
  {
    id: "51b6a7c4-vapm-4fbc-a372-c44df340560w1",
    method: "virtual_account",
    currency: "NGN",
    priority: null,
    channel: {
      ui_code: "wema_bank",
      name: "Wema bank",
      identifier: "BNC25235246",
      adapter: "wema",
      priority: null,
    },
    provider: { identifier: "korapay_int_8", name: "korapay", bank: "3" },
  },
  {
    id: "51b6a7c4-vapm-4fbc-a372-c44df340560w2",
    method: "virtual_account",
    currency: "NGN",
    priority: null,
    channel: {
      ui_code: "providus_bank",
      name: "Providus Bank",
      identifier: "BNC25235246",
      adapter: "providus",
      priority: null,
    },
    provider: { identifier: "fincra_int_8", name: "fincra", bank: "2" },
  },
  {
    id: "51b6a7c4-vapm-4fbc-5555-c44df340560w5",
    method: "wallet_vega",
    currency: "JPY",
    priority: null,
  },
  {
    id: "51b6a7c4-11e0-4fbc-3472-c433df3xx1240560q4rf22-wallet_muchbetter",
    method: "wallet_muchbetter",
    currency: "UAH",
    priority: 90,
  },
  {
    id: "51b6a7c4-vapm-4fbc-a372-c44df340560w5",
    method: "wallet_opay",
    currency: "USD",
    priority: null,
  },
  {
    id: "51b6a7c4-vapm-4fbc-a372-c44df340560w5qr",
    method: "wallet_opay_qr",
    currency: "USD",
    priority: null,
  },
  {
    id: "51b6a7c4-vapm-4fbc-a372-c44df340560w5_payadmit",
    method: "mbway",
    currency: "EUR",
    priority: null,
    provider: {
      identifier: "payadmit_int_8",
      name: "payadmit",
    },
  },
  {
    id: "51b6a7c4-vapm-4fbc-a372-c44df340560w5",
    method: "bank_transfer_africa",
    currency: "NGN",
    priority: null,
    provider: {
      identifier: "bank_transfer_africa_int_8",
      name: "fincra",
      bank: "2",
    },
  },
  {
    id: "51b6a7c4-vapm-4fbc-a372-c44df340560w44",
    method: "bank_transfer_africa",
    currency: "USD",
    priority: null,
    provider: {
      identifier: "bank_transfer_africa_int_8",
      name: "korapay",
    },
  },
  {
    id: "51b6a7c4-11e0-4fbc-a372-c44df340560q",
    method: "card_redirect",
    currency: "UAH",
    priority: 10,
    provider: { identifier: "certus_integration_25236", name: "Certus" },
  },
  {
    id: "51b6a7c4-11e0-4fbc-a372-c44df340560q4rf2",
    method: "wallet_tpaga",
    currency: "UAH",
    priority: 10,
  },
  {
    id: "51b6a7c4-11e0-4fbc-a372-c44df340560q4rf22",
    method: "wallet_chek",
    currency: "UAH",
    priority: 10,
  },
  {
    id: "51b6a7c4-11e0-4fbc-a372-c433df340560q4rf22",
    method: "wallet_mach",
    currency: "UAH",
    priority: 10,
  },
  {
    id: "51b6a7c4-11e0-4fbc-3472-c433df340560q4rf22",
    method: "wallet_todito",
    currency: "UAH",
    priority: 10,
  },
  {
    id: "51b6a7c4-11e0-4fbc-3472-c433df3xx1240560q4rf22-wallet_payz",
    method: "wallet_payz",
    currency: "JPY",
    priority: 90,
  },
  {
    id: "51b6a7c4-11e0-4fbc-3472-c433df3xx1240560q4rf22",
    method: "wallet_palmpay",
    currency: "UAH",
    priority: 90,
  },
  {
    id: "51b6a7c4-11e0-4fbc-347-3472-c433df3xx1244340560q4rf22",
    method: "pay_code_palmpay",
    currency: "UAH",
    priority: 32,
  },
  {
    id: "51b6a7c4-11e0-4fbc-3472-c433df342360q4rf22",
    method: "cash_chile",
    currency: "UAH",
    priority: 10,
  },
  {
    id: "51b6a7c4-121e0-4fbc-3472-c433df342360q4rf22",
    method: "bank_transfer_khipu",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "banco falabella1",
      ui_code: "banco_falabella",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "51b6a7c4-121e0-4fbc-3472-c433df342360q4rf212312",
    method: "interac_etransfer",
    currency: "UAH",
    priority: 10,
  },
  {
    id: "51b6a7c4-121e0-4fbc-3472-c433df342360q4rf2122",
    method: "bank_transfer_khipu",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "banco_estado1",
      ui_code: "banco_estado",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "51b6a7c4-121e0-4fbc-3472-c433df342360q4rf2123232132",
    method: "bank_transfer_khipu",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "banco_de_chile1",
      ui_code: "banco_de_chile",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "51b61237c4-121e0-4fbc-3472-c433df342360q4rf22",
    method: "bank_transfer_peru",
    currency: "UAH",
    priority: 10,
  },
  {
    id: "5112a7c4-121e0-4fbc-3472-c433df342360q4rf22",
    method: "bank_transfer_chile",
    currency: "UAH",
    priority: 10,
  },
  {
    id: "51b6a7c4-11e0-4f23bc-a372-c44d4оа0560t131313124",
    method: "popypara",
    priority: 7,
    currency: "UAH",
  },
  {
    id: "5111e0-4f23bc-a372-c44d4о23fа0560t13124",
    method: "parolapara",
    priority: 7,
    currency: "UAH",
  },
  {
    id: "51b6a7c4-11e0-4f23bc-a372-c44d4оddа0560t13112313124",
    method: "parazula",
    priority: 7,
    currency: "UAH",
  },
  {
    id: "51b6a7c4-11e0-4f23bc-a3s32ddа0560t13112313124",
    method: "papara",
    priority: 7,
    currency: "UAH",
  },
  {
    id: "51b6a7c4-11e0-4f23bpayfixc-a3s32ddа0560t13112313124",
    method: "payfix",
    priority: 7,
    currency: "UAH",
  },
  {
    id: "51b6a7c4-11e0-ff2-a3s32ddа0560t13112313124",
    method: "mefete",
    priority: 7,
    currency: "UAH",
  },
  {
    id: "51b6a7c4-11e0-4f23bc-a372-c10t13112313124",
    method: "pep",
    priority: 7,
    currency: "UAH",
  },
  {
    id: "51b6a7c4-11e0-4f23bc-a37-4324ffkkekl",
    method: "moneytolia",
    priority: 2,
    currency: "TRY",
  },
  {
    id: "51b6a7c4-11e0-4fbc-a372-c44d4оа0560t",
    method: "card_redirect",
    priority: 25,
    currency: "UAH",
    provider: { identifier: "baz_pay_int_8", name: "Bazpay" },
  },
  {
    id: "51b6a7c4-11e0-4fbc-a372-c44d4оа0560t1",
    method: "card_redirect",
    priority: 25,
    currency: "UAH",
    provider: { identifier: "msb_pay_int_8", name: "msb" },
  },
  {
    id: "51b6a7c4-11e0-4f23bc-a372-c44d4оа0560t1313",
    method: "card_redirect",
    priority: 25,
    currency: "UAH",
    provider: { identifier: "pagsmile_pay_int_8", name: "pagsmile" },
  },
  {
    id: "51b61237c4-121e0-4fbc-3472-c433df342360q4rf22aee",
    method: "bank_transfer_argentina",
    currency: "USD",
    priority: 10321312,
  },
  {
    id: "51b61237c4-121e0-4fbc-3472-c433323df342360q4rf22aee",
    method: "bank_transfer_turkey",
    currency: "TRY",
  },
  {
    id: "51b6a7c4-vapm-4fbc-a372-c44df340560w3",
    method: "virtual_account",
    currency: "NGN",
    priority: null,
    channel: {
      ui_code: "globus_bank",
      name: "Globus Bank",
      identifier: "BNC25235246",
      adapter: "globus",
      priority: null,
    },
    provider: { identifier: "fincra_int_9", name: "fincra", bank: "1" },
  },
  {
    id: "51b6a7c4-vapm-4fbc-a372-c44df340560w2",
    method: "virtual_account",
    currency: "NGN",
    priority: null,
    channel: {
      ui_code: "providus_bank",
      name: "Providus Bank",
      identifier: "BNC25235246",
      adapter: "providus",
      priority: null,
    },
    provider: { identifier: "fincra_int_8", name: "fincra", bank: "2" },
  },
  {
    id: "51b6a7c4-11e0-4fbc-a372-c44df3405623122333",
    method: "deposit_opay",
    currency: "UAH",
    priority: null,
    provider: null,
    public_fields: [
      {
        name: "betting_merchant_name",
        value: "test_merchant",
      },
    ],
  },
  {
    id: "51b6a7c4-11e0-4fbc-a372-c44df3405623122333pamplay",
    method: "deposit_palmpay",
    currency: "UAH",
    priority: null,
    provider: null,
    public_fields: [
      {
        name: "betting_merchant_name",
        value: "test_merchant",
      },
    ],
  },
  {
    id: "51b6a7c4-11e0-4fbc-a372-c44df3405623122333pamplayError",
    method: "deposit_palmpay",
    currency: "NGN",
    priority: null,
    provider: null,
    public_fields: [
      {
        name: "betting_merchant_name",
        value: "test_merchant",
      },
    ],
  },
  {
    id: "51b6a7c4-121e0-4fbc-3472-c433df342360q4rf21232321321",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "access_bank",
      ui_code: "access_bank",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "b3c4caae-5485-47c9-9352-5a173b66b4ad",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "asosavings_&_loan",
      ui_code: "asosavings_&_loan",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "c6458b9c-5c7f-4019-8d75-4d3c0df4b499",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "nigeria_int'l_bank",
      ui_code: "nigeria_int'l_bank",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "a7e9c4d5-4c8e-40ec-91e4-f6b94c7e85d5",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "diamond_bank",
      ui_code: "diamond_bank",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "cbe054f7-49c9-4e3e-80ea-3f97c3e9e4da",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "ecobank_nigeria",
      ui_code: "ecobank_nigeria",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "51b61237c4-121e0-4fbc-3472-japan",
    method: "bank_transfer_japan",
    currency: "JPY",
  },
  {
    id: "51b61237c4-121e0-4fbc-3472-korea",
    method: "bank_transfer_south_korea",
    currency: "KRW",
  },
  {
    id: "d457eb96-2145-4d8e-bd0a-8d0f7b34f3e5",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "enterprise_bank",
      ui_code: "enterprise_bank",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "e67063b9-1344-4bb8-8758-3fa4f3bcbfd5",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "first_city_monument_bank",
      ui_code: "first_city_monument_bank",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "f27be076-684f-41e6-846b-7b8c3c9df7c5",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "fidelity_bank",
      ui_code: "fidelity_bank",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "f27be076-684f-41e6-846b-7b8c3c9df712321312c5",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "vfd",
      ui_code: "vfd",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "d9c5d4a5-8646-4e9f-8c48-1d3e7b1cf4d5",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "first_bank_of_nigeria",
      ui_code: "first_bank_of_nigeria",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "e7c3d5b8-94f6-44b9-b8f4-7e3f9d8e4f6d",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "guaranty_trust_bank",
      ui_code: "guaranty_trust_bank",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "e7c3d5b8-94f6-44b9-b8f4-7e3f9d8e4f6d321233",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "Wema bank",
      ui_code: "wema_bank",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "f9b5e7a6-56d3-43b9-8f4d-1d4f9e3f9c7d",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "heritage_banking",
      ui_code: "heritage_banking",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "a9c5d6e8-74f9-4b7e-8d3f-5d8c3e7b4f9d",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "jaiz_bank",
      ui_code: "jaiz_bank",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "b9c5e6d8-94f3-4b7d-8f9e-7c5d8e3f4a9d",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "keystone_bank",
      ui_code: "keystone_bank",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "c9d5e7b8-74f2-4b9e-8c5d-9f4e7d3a6b9d",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "parkway_bank",
      ui_code: "parkway_bank",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "d9e5f7c8-84f3-4b9e-8d3c-7f5e9d3a6c9d",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "polaris_bank",
      ui_code: "polaris_bank",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "e9f5d7c8-94f3-4b9e-8d7c-8f5e7d3a6c9d",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "stanbic-ibtc_bank",
      ui_code: "stanbic-ibtc_bank",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "51b6a7c4-vapm-4fbc-a372-c44df34056032342w4512333",
    method: "ussd",
    currency: "USD",
    priority: null,
    channel: {
      ui_code: "providus_bank",
      name: "Providus Bank",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "f9d5e7c8-74f2-4b7d-8f9c-7e5d9f3a6c9d",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "standard_chartered_bank",
      ui_code: "standard_chartered_bank",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "a9d5e6c8-84f2-4b7e-8c9f-6d5e7f3a9c6d",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "sterling_bank",
      ui_code: "sterling_bank",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "b9f5d7e8-94f2-4b9e-8c7d-7e5d8f3a9c6d",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "united_bank_for_africa",
      ui_code: "united_bank_for_africa",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "c9e5d7f8-74f2-4b7d-8c9e-6d5e8f3a9c6d",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "union_bank_of_nigeria",
      ui_code: "union_bank_of_nigeria",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "d9e5c7f8-84f3-4b9e-8d7c-7e5d9f3a9c6d",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "unity_bank",
      ui_code: "unity_bank",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "e9f5d7c8-74f3-4b9e-8c7d-6f5e8d3a9c6d",
    method: "ussd",
    currency: "UAH",
    priority: 10,
    channel: {
      name: "zenith_bank",
      ui_code: "zenith_bank",
      identifier: "BNC25235246",
      adapter: "test",
      priority: null,
    },
  },
  {
    id: "f27be076-684f-41e6-846b-7b8c3c93213213214cdcdf7c5",
    method: "bank_code",
    name: "RBK Bank",
    code: "314",
  },
  {
    id: "f27be076-684f-41234dasfe1e6-846b-7b8c3c9df7c5",
    method: "bank_code",
    name: "Kazpost Bank",
    code: "313",
  },
  {
    id: "51b6a7c4-11e0-4fbc-a372-c44df3fsdf123mfkdsfsdfsd40560t",
    method: "p2c",
    priority: 25,
    currency: "UAH",
    provider: null,
  },
];

export default availablePaymentMethods;
