import { useCallback, useEffect, useState, useRef } from "preact/hooks";
import state from "./state";
import { deepEqual } from "./deepEqual";
import brand from "../services/brand";
import theme from "./theme";
import colorSchemes from "../colorThemes/colorSchemes";
import testData from "./testData";
import connection from "./connection";
import { getColorThemeWithOverrides, getFontUrl } from "./utils";
import { ThemeKeys } from "../constants/themeConstants";
import api from "./api";
import logger from "./logger";

export const useClickOutside = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [ref, callback]);
};

export const useData = (initialData, deps = []) => {
  const [data, setData] = useState(initialData);

  const handleDataUpdated = useCallback(
    (newData) => {
      const checkDiff = deps.reduce((acc, val) => {
        if (!acc) {
          if (!deepEqual(initialData[val], newData[val])) {
            acc = true;
          }
        }

        return acc;
      }, false);

      if ((!deepEqual(data, newData) && checkDiff) || deps.length === 0) {
        setData(newData);
      }
    },
    [data, deps, initialData],
  );

  useEffect(() => {
    state.events.on("dataUpdated", handleDataUpdated);

    return () => {
      state.events.off("dataUpdated", handleDataUpdated);
    };
  }, [handleDataUpdated, initialData]);

  return data || initialData;
};

export const useConnection = (deps = []) => {
  const [data, setData] = useState(connection.getConnectionStatus());

  const handleDataUpdated = useCallback(
    (newData) => {
      setData(newData);
    },
    [data, ...deps],
  );

  useEffect(() => {
    connection.events.on("connectionUpdated", handleDataUpdated);

    return () => {
      connection.events.off("connectionUpdated", handleDataUpdated);
    };
  }, [handleDataUpdated]);

  return data;
};

export const useColorScheme = (currentTheme) => {
  const brandScheme = brand.getColorScheme();
  const brandData = brand.getBrand();
  const themeScheme = theme.getColorScheme(currentTheme);
  const config = theme.getThemeConfig(currentTheme);
  const mainFont = brandData?.hpp_main_font;
  const fontUrl = getFontUrl(mainFont);

  const currentState = state.get();
  const { options } = currentState;

  const colorTheme = getColorThemeWithOverrides({
    colorSchemes,
    themeScheme,
    brandScheme,
    defaultScheme: "blue",
    brandData,
    customization: options?.customization || config?.customizations,
  });

  theme.loadFontByUrl(fontUrl);

  return { colorTheme };
};

const getLogoFromPublicFields = () => {
  const selectedMethod = state.getSelectedPaymentMethod();
  const publicFields = selectedMethod?.public_fields || [];

  const logoField = publicFields?.find(
    (field) => field?.name === "hpp_logo_name",
  );

  switch (logoField?.value?.toLowerCase()) {
    case ThemeKeys.MUNZEN:
      return theme.getLogo(ThemeKeys.MUNZEN);

    default:
      return null;
  }
};

export const useLogo = (currentTheme) => {
  const { sessionId, signature } = state.getSession();
  const brandData = brand.getBrand();
  const isNonameTheme = brandData?.theme?.includes("noname");
  const isTestMode = testData.isTestMode(sessionId, signature);
  const themeLogo = theme.getLogo(currentTheme);
  const publicFieldsLogo = getLogoFromPublicFields();

  if (publicFieldsLogo) {
    return publicFieldsLogo;
  }

  if (isTestMode && brandData?.logo) {
    return brandData?.logo;
  }

  if (typeof themeLogo === "string") {
    return themeLogo;
  }

  if (isNonameTheme) {
    return "";
  }

  if (brandData?.logo_white) {
    return brandData?.logo_white;
  }

  if (!isTestMode) {
    return `/api/brand/logo/white/${sessionId}/${signature}`;
  }

  return "";
};

export const useExpirationStatus = (expirationDate) => {
  const [isExpired, setIsExpired] = useState(() => {
    return expirationDate ? new Date(expirationDate) < new Date() : false;
  });

  useEffect(() => {
    if (!expirationDate) return;

    const now = new Date();
    const expiresAt = new Date(expirationDate);

    if (expiresAt <= now) {
      setIsExpired(true);

      return;
    }

    const timeout = setTimeout(() => {
      setIsExpired(true);
    }, expiresAt - now);

    return () => clearTimeout(timeout);
  }, [expirationDate]);

  return isExpired;
};

/**
 * Custom hook for antifraud logic
 * @param {Object} brandData - The brand data object to monitor.
 */
export const useAntifraud = (brandData) => {
  const brandDataRef = useRef();

  useEffect(() => {
    if (
      brandData?.antifraud_enabled &&
      brandData?.antifraud_provider === "covery"
    ) {
      const { sessionId, signature } = state.getSession();

      if (typeof window !== "undefined") {
        window.handleCoveryFpKey = (deviceFingerprint) => {
          state.setDeviceFingerprint(deviceFingerprint);
        };
      }

      api
        .getCoveryScript(sessionId, signature)
        .then((script) => {
          if (typeof script === "string") {
            executeScript(script.replace(/<\/?script[^>]*>/gi, ""));
          } else {
            logger.error("Invalid antifraud script:", script);
          }
        })
        .catch((error) => {
          logger.error("Error getting antifraud script:", error);
        });
    }

    brandDataRef.current = brandData;
  }, [brandData]);
};

function executeScript(script) {
  try {
    const scriptElement = document.createElement("script");
    scriptElement.text = script;
    document.head.appendChild(scriptElement);
  } catch (error) {
    logger.error("Error executing antifraud script:", error);
  }
}

export default null;
