import defaultThemeConfig from "../commons/configs/defaultConfig";
import { ThemeConfig } from "../commons/interfaces/configInterface";

const kombineThemeConfig: ThemeConfig = {
  ...defaultThemeConfig,
  isHideBackToActionTitle: true,
  cardHolderInputPlaceHolder: "John Doe",
  cardInputPlaceHolder: "0000 0000 0000 0000",
  formButtonTitle: "DEPOSIT",
  cardHolderValidation: "kombine",
  innerInputRememberMeShow: false,
  showRememberMeTooltip: true,
  showDateTooltip: false,
  formButtonCurrency: true,
  isHideDescription: true,
  inputCardLabel: "Card number:",
  inputCardHolderLabel: "Name on card:",
  inputDateLabel: "Expires date:",
  getDisableButtonStatus: ({ values, errors }) => {
    const everyFieldsAreTyped = ["card", "date", "cvv", "cardHolder"].every(
      (field) => !!values[field],
    );

    return !(everyFieldsAreTyped && Object.keys(errors).length === 0);
  },
  getFieldOrder: ({ field, index }) => {
    if (field.name === "cardHolderName") {
      return 1;
    }

    if (field.name === "cardNumber") {
      return 0;
    }

    return index;
  },
};

export default kombineThemeConfig;
