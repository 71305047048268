import { COLORS } from "../constants/themeConstants";

const defaultElementStylesSchema = {
  mainBackGroundColor: "rgb(240, 244, 245)",
  inputColorPlaceholder: "#5A747A",
  inputPlaceholderFontSize: "18px",
  inputFocusedBackground: "#fff",
  mainFormFontSize: "18px",
  inputCvvDateWidth: "clamp(138px, 43vw, 180px)",
  rememberMeCheckboxPadding: "0",
  rememberMeCheckboxMargin: "10px 0 8px 28px;",
  rememberMeCheckboxSize: "16px",
  rememberMeCheckboxTitleColor: COLORS.DEFAULT_BLACK,
  rememberMeCheckboxCheckedIconTop: "0px",
  rememberMeCheckboxBorderChecked: COLORS.GRAY,
  rememberMeCheckboxBorderNotChecked: COLORS.GRAY,
  rememberMeCheckboxBorderRadius: "4px",
  rememberMeCheckboxBackground: "transparent",
  inputPlaceHolderMarginLeft: "12px",
  inputColorFocusedPlaceholder: "#101820",
  inputHeight: "40px",
  elementBorderRadius: "20px",
  buttonMargin: "auto auto 16px",
  iframeButtonMargin: "0 auto 16px",
  inputBackgroundColor: "#fff",
  inputColor: "#101820",
  formMaxWidth: "440px",
  bottomError: "0",
  popupTextTitleColor: "#101820",
  appBorderRadius: "16px",
  infoBlockMargin: "32px",
  boxShadow: "0px 0px 15px rgba(123, 148, 157, 0.5)",
  notification: {
    width: "100%",
    top: 0,
    mt: 0,
    borderTopRadius: "16px",
    borderBottomRadius: "0",
  },
};

const kombineElementStylesSchema = {
  mainBackGroundColor: "#031938",
  headerTitleColor: "#9ba2af",
  inputColorPlaceholder: COLORS.WHITE,
  inputPlaceholderFontSize: "16px",
  inputPlaceHolderMarginLeft: "0px",
  inputColorFocusedPlaceholder: COLORS.WHITE,
  mainFormFontSize: "16px",
  buttonColor: "linear-gradient(0deg, #D93A00 0%, #FFC20D 100%)",
  disableButtonOpacity: "0.2",
  inputHeight: "56px",
  inputGap: "0",
  elementBorderRadius: "8px",
  buttonMargin: "0 auto auto",
  iframeButtonMargin: "0 auto 16px",
  rememberMeCheckboxTitleColor: COLORS.WHITE,
  rememberMeCheckboxBackground: COLORS.WHITE,
  rememberMeCheckboxBorderChecked: COLORS.WHITE,
  rememberMeCheckboxCheckedIconTop: "-1px",
  rememberMeCheckboxBorderNotChecked: "#FFFFFF99",
  rememberMeCheckboxBorderRadius: "3px",
  rememberMeCheckboxPadding: "8px 0 0 0",
  rememberMeCheckboxMargin: "0 0 24px 16px",
  rememberMeCheckboxSize: "24px",
  inputBackgroundColor: "#05224C",
  inputColor: "#FFFFFF4D",
  formMaxWidth: "390px",
  inputCvvDateWidth: "clamp(138px, 43vw, 171px)",
  managePopupBackgroundColor: "#031938",
  inputFocusedBackground: "#10366B",
  managePopupElementHeight: "56px",
  appBorderRadius: "0px",
  bottomError: "12",
  infoBlockMargin: "16px",
  boxShadow: "",
  subMainInfoTexFontWeight: "400",
  subMainInfoTexFontSize: "12px",
  notification: {
    width: "358px",
    top: "16px",
    mt: "16px",
    borderTopRadius: "8px",
    borderBottomRadius: "8px",
  },
  popupTextTitleColor: COLORS.WHITE,
};

export default {
  defaultElementStylesSchema,
  kombineElementStylesSchema,
};
