import { RefObject } from "preact";
import defaultColorSchema from "../colorThemes/defaultColorScheme";
import { OPERATOR_DATA } from "../constants/operator_data";
import logger from "./logger";
import state from "./state";

type SessionData = {
  type: string;
  sessionId: string;
  signature: string;
};

const truncateText = (
  text: string,
  setIsTruncateText: (status: boolean) => void,
) => {
  if (text.length > 21) {
    if (setIsTruncateText) setIsTruncateText(true);

    return `${text?.slice(0, 12)}...${text?.slice(-3)}`;
  }

  return text;
};

function getPrefixAndRegexp(
  currency?: string,
  ui_code?: string,
): { prefix: string; regexp: RegExp } | null {
  for (const entry of OPERATOR_DATA) {
    if (
      entry.currency.toLowerCase() === currency?.toLowerCase() &&
      entry.ui_code.toLowerCase().replace(/\s+/g, "_") ===
        ui_code?.toLowerCase().replace(/\s+/g, "_")
    ) {
      return { prefix: entry.prefix, regexp: entry.regexp };
    }
  }

  return null;
}

interface AdjustFontSizeParams {
  ref: RefObject<HTMLElement>;
  length: number;
  size: number;
}

const adjustFontSize = ({ ref, length, size }: AdjustFontSizeParams): void => {
  if (ref.current) {
    const textLength = ref.current.textContent?.length || 0;

    let fontSize = size;

    if (textLength > length) {
      fontSize = size - (textLength - length) * 0.5;

      fontSize = Math.max(fontSize, 10);
    }

    ref.current.style.fontSize = `${fontSize}px`;
  }
};

const getFontUrl = (mainFont: string): string => {
  const fontName = mainFont === "WalsheimLC" ? "Rubik" : mainFont;
  return `https://fonts.googleapis.com/css2?family=${fontName}:wght@300..900&display=swap`;
};

interface BrandData {
  hpp_background_colour?: string;
  hpp_accent_colour?: string;
  hpp_loader_name?: string;
  hpp_main_font?: string;
  hpp_main_background_colour?: string;
}

const getColorThemeWithOverrides = ({
  colorSchemes,
  themeScheme,
  brandScheme,
  defaultScheme,
  brandData,
  customization,
}: {
  colorSchemes: { [key: string]: { [key: string]: string } };
  themeScheme: string;
  brandScheme: string;
  defaultScheme: string;
  brandData: BrandData;
  customization: Record<string, any>;
}) => {
  const defaultFonts =
    "'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif";
  let themeValues = colorSchemes?.[themeScheme];
  const brandValues =
    colorSchemes?.[brandScheme] || colorSchemes?.[defaultScheme];

  const {
    hpp_main_font,
    hpp_background_colour,
    hpp_accent_colour,
    hpp_loader_name,
    hpp_main_background_colour,
  } = brandData || {};

  const isTestMode = state.isTestMode();

  if (isTestMode && themeValues) {
    themeValues = {
      ...themeValues,
      ...(hpp_accent_colour && { mainColor: hpp_accent_colour }),
      ...(hpp_background_colour && {
        formHeaderBackground: hpp_background_colour,
      }),
      ...(hpp_main_background_colour && {
        mainBackGroundColor: hpp_main_background_colour,
      }),
      ...(hpp_main_font && { mainFont: hpp_main_font }),
      ...(hpp_accent_colour && { buttonColor: hpp_accent_colour }),
    };
  }

  const mainFont =
    customization?.main_font || hpp_main_font === "WalsheimLC"
      ? defaultFonts
      : hpp_main_font ||
        themeValues?.mainFont ||
        brandValues?.mainFont ||
        defaultFonts;

  const buttonColor =
    customization?.accent_colour ||
    themeValues?.buttonColor ||
    hpp_accent_colour ||
    brandValues?.buttonColor ||
    defaultColorSchema.buttonColor;

  const primaryButtonColor =
    customization?.accent_colour ||
    hpp_accent_colour ||
    themeValues?.primaryButtonColor ||
    brandValues?.buttonColor ||
    defaultColorSchema.primaryButtonColor;

  const grayButtonColor =
    customization?.accent_colour ||
    hpp_accent_colour ||
    themeValues?.grayButtonColor ||
    brandValues?.buttonColor ||
    defaultColorSchema.primaryButtonColor;

  const secondaryButtonColor =
    customization?.accent_colour ||
    hpp_accent_colour ||
    themeValues?.secondaryButtonColor ||
    brandValues?.buttonColor ||
    defaultColorSchema.secondaryButtonColor;

  const mainColor =
    customization?.accent_colour ||
    themeValues?.mainColor ||
    hpp_accent_colour ||
    brandValues?.mainColor ||
    defaultColorSchema.mainColor;

  const loaderName =
    customization?.loader_name?.toLowerCase() === "default"
      ? themeValues?.loaderName ||
        hpp_loader_name ||
        brandValues?.loaderName ||
        defaultColorSchema.loaderName
      : customization?.loader_name ||
        themeValues?.loaderName ||
        hpp_loader_name ||
        brandValues?.loaderName ||
        defaultColorSchema.loaderName;

  const formHeaderBackground =
    customization?.background_colour ||
    themeValues?.formHeaderBackground ||
    hpp_background_colour ||
    brandValues?.formHeaderBackground ||
    defaultColorSchema.formHeaderBackground;

  const headerGradient = customization?.background_colour
    ? `radial-gradient(at top, ${customization?.background_colour}, ${customization?.background_colour} 92%, transparent 50%)`
    : hpp_background_colour
      ? `radial-gradient(at top, ${hpp_background_colour}, ${hpp_background_colour} 92%, transparent 50%)`
      : themeValues?.headerGradient ||
        brandValues?.headerGradient ||
        defaultColorSchema.headerGradient;

  const mainBackGroundColor =
    customization?.main_background_colour ||
    themeValues?.mainBackGroundColor ||
    hpp_main_background_colour ||
    brandValues?.hpp_main_background_colour ||
    defaultColorSchema.mainBackGroundColor;

  return {
    ...(themeValues || brandValues || defaultColorSchema),
    ...{
      formHeaderBackground,
      headerGradient,
      mainColor,
      buttonColor,
      loaderName,
      mainFont,
      regularFont: mainFont,
      mediumFont: mainFont,
      mainBackGroundColor,
      primaryButtonColor,
      secondaryButtonColor,
      grayButtonColor,
    },
  };
};

const debounce = <T extends (...args: unknown[]) => void>(
  func: T,
  delay: number,
): ((...args: Parameters<T>) => void) => {
  let timerId: ReturnType<typeof setTimeout>;

  return (...args: Parameters<T>) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

type InputType = number | string | null | undefined;

const getMax = (num1: InputType, num2: InputType): number => {
  const n1 = parseFloat(String(num1));
  const n2 = parseFloat(String(num2));

  const validNum1 = !isNaN(n1) ? n1 : Number.NEGATIVE_INFINITY;
  const validNum2 = !isNaN(n2) ? n2 : Number.NEGATIVE_INFINITY;

  return Math.max(validNum1, validNum2);
};

const sum = (...numbers: number[]): number => {
  if (numbers.length === 0) return 0;

  const getDecimalPlaces = (num: number): number => {
    const parts = num.toString().split(".");
    return parts[1]?.length || 0;
  };

  if (numbers.some((num) => !isFinite(num))) {
    logger.error("Invalid input detected in sum function", {
      error: "Non-finite number provided",
      numbers,
    });

    throw new Error(
      "All inputs must be finite numbers. Found invalid input in: " +
        JSON.stringify(numbers),
    );
  }

  const maxDecimalPlaces = Math.max(...numbers.map(getDecimalPlaces));

  const multiplier = Math.pow(10, maxDecimalPlaces);

  const totalSum = numbers.reduce(
    (acc, num) => acc + Math.round(num * multiplier),
    0,
  );

  return totalSum / multiplier;
};

const getSessionFromUrl = (): SessionData => {
  if (typeof window === "undefined") {
    return { type: "", sessionId: "", signature: "" };
  }

  let path = window.location.pathname;

  path = path.replace(/^\/+/, "").replace(/\/+$/, "");

  const [type = "", sessionId = "", signature = ""] = path.split("/");

  return { type, sessionId, signature };
};

export {
  truncateText,
  getPrefixAndRegexp,
  adjustFontSize,
  debounce,
  getMax,
  getFontUrl,
  getColorThemeWithOverrides,
  sum,
  getSessionFromUrl,
};
