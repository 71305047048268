export const ThemeKeys = {
  DEFAULT: "default",
  NONAME: "noname",
  KOMBINE: "kombine",
  N1: "N1_brand",
  SETTLEPAY: "settlepay",
  SETTLEPAY_NONAME: "settlepay_noname",
  N1_NONAME: "N1_noname",
  MUNZEN: "munzen", // only for logo with public fields
  PM: "PM_brand",
  ERROR: "error",
};

export const COLORS = {
  BLUE: "#0062eb",
  BlUE_LIGHT: "#E5F0FF",
  DARK_GREEN: "#08728A",
  GREEN_LIGHT: "#D1F0F1",
  GREEN: "#0FCA87",
  DANGER: "#EB0075",
  PINK: "#6656a5",
  DEFAULT_BLACK: "#101820",
  DEFAULT_DARK_BLUE: "#353645",
  DEFAULT_DARK_PURPLE: "#4e47d5",
  DEFAULT_WHITE: "#fff",
  GRAY: "#5A747A",
  LIGHT_GRAY: "#e5eaeb",
  GREY_WHITE: "#F0F4F5",
  DEFAULT_GREY: "#c4c4c4",
  MAIN_BODY_GREY: "#D9DFE1",
  WHITE: "#ffffff",
};
