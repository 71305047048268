export enum PM_COLORS {
  PRIMARY = "#009E69",
  SECONDARY = "#F2F740",
  GRAY = "#FAF9F8",
  GRAY_2 = "#DAD6CE",
  GRAY_3 = "#B7B9BC",
  GRAY_4 = "#F5F5F5",
  GRAY_5 = "#7B756B",
  GRAY_6 = "#e8e8ea",
  GRAY_SECONDARY = "#7b756b",
  GRAY_DARK = "#292621",
  GRAY_DARK_LIGHT = "#2929291A",
  GRAY_DARK_2 = "#F5F4F1",
  GRAY_LIGHT = "#bdb8ad",
  DEFAULT_WHITE = "#fff",
  BORDER = "#E6E3DE",
  DANGER = "#FF3333",
  WARNING = "#F69B5A",
  ERROR = "#FF1F00",
  ERROR_BACKGROUND = "#FF1F001A",
}
