import { ThemeKeys } from "../../../constants/themeConstants";
import defaultThemeConfig from "./defaultConfig";
import favicon_error from "../../../assets/icons/favicon_error.ico";
import kombineThemeConfig from "../../kombine/config";
import pmThemeConfig from "../../PM_brand/config";

export const themes = {
  [ThemeKeys.DEFAULT]: defaultThemeConfig,
  [ThemeKeys.KOMBINE]: kombineThemeConfig,
  [ThemeKeys.PM]: pmThemeConfig,
  [ThemeKeys.NONAME]: {
    ...defaultThemeConfig,
  },
  [ThemeKeys.MUNZEN]: {
    ...defaultThemeConfig,
  },
  [ThemeKeys.N1]: {
    ...defaultThemeConfig,
    title: "n1betpay.ng",
    isHideBackToActionTitle: true,
    isHideTransactionId: true,
    isHideDescription: true,
    isShowCurrencyCopy: true,
    totalDueTitle: "amount",
  },
  [ThemeKeys.N1_NONAME]: {
    ...defaultThemeConfig,
    title: "n1betpay.ng",
    isHideBackToActionTitle: true,
    isHideTransactionId: true,
    isHideDescription: true,
    isShowCurrencyCopy: true,
    totalDueTitle: "amount",
  },
  [ThemeKeys.SETTLEPAY]: {
    ...defaultThemeConfig,
    title: "Settlepay",
  },
  [ThemeKeys.SETTLEPAY_NONAME]: {
    ...defaultThemeConfig,
    title: "Settlepay",
  },
  [ThemeKeys.ERROR]: {
    favicon: favicon_error,
    title: "payment error",
  },
};
