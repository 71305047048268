import availablePaymentMethods from "./availablePaymentMethodsForTest";

const supportedPaymentMethods = [
  ...availablePaymentMethods,
  ...[
    {
      id: "51b6a7c4-11e0-4fbc-aaaa-c44df340560w",
      method: "apple_pay",
      currency: "UAH",
      priority: null,
    },
    {
      id: "51b6a7c4-11e0-4fbc-gggg-c44df340560w",
      method: "google_pay",
      currency: "UAH",
      priority: null,
    },
    {
      id: "51b6a7c4-34f2-4fbc-a372-c44df340560w",
      method: "crypto",
      currency: "USDTERC20",
      priority: null,
    },
    {
      id: "51b6a7c4-34f2-o5es-8e4k-c44df340560w",
      method: "upi",
      priority: 25,
      currency: "INR",
    },
    {
      id: "51b6a7c4-t47j-o5es-8e4k-c44df340560w",
      method: "boleto",
      priority: 27,
      currency: "INR",
    },
    {
      id: "51b6a7c4-t47j-o5es-8e4k-c44dfk3w560w",
      method: "lottery",
      priority: 22,
      currency: "INR",
    },
    {
      id: "51b6a7c4-t47j-o5es-k4g7-c44df340560w",
      method: "pix",
      priority: 21,
      currency: "INR",
    },
    // {
    //   id: "51b6a7c4-t47j-3g7j-k4g7-c44df340560w",
    //   method: "card",
    //   priority: 21,
    //   currency: "INR",
    // },
    {
      id: "51b6a7c4-4tg4-3g7j-k4g7-c44df340560w",
      method: "p2p",
      priority: 21,
      currency: "INR",
    },
    {
      id: "51b6a7c4-11e0-4fbc-a372-c44df340560q",
      method: "card_redirect",
      currency: "UAH",
      priority: 10,
      provider: { identifier: "certus_integration_25236", name: "Certus" },
    },
    {
      id: "51b6a7c4-11e0-4fbc-a372-c44d4оа0560t",
      method: "card_redirect",
      priority: 25,
      currency: "UAH",
      provider: { identifier: "baz_pay_int_8", name: "Bazpay" },
    },
    {
      id: "51b6a7c4-11e0-4fbc-a372-c33d4оа0560t",
      method: "apple_pay_redirect",
      priority: 25,
      currency: "UAH",
      provider: { identifier: "baz_pay_int_8", name: "Bazpay" },
    },
    {
      id: "51b6a7c4-11e0-4fbc-a372-c22d4оа0560t",
      method: "google_pay_redirect",
      priority: 25,
      currency: "UAH",
      provider: { identifier: "baz_pay_int_8", name: "Bazpay" },
    },
    {
      id: "51b6a7c4-vapm-4fbc-a372-c44df340560w",
      method: "virtual_account",
      currency: "NGN",
      priority: null,
    },
    {
      id: "e9f5d7c8-74f3-4b9e-8c7d-6f5e8d3a9123434fdsc6d",
      method: "bank_transfer_argentina",
      currency: "USD",
    },
  ],
];

export default supportedPaymentMethods;
