function isObject(obj) {
  if (typeof obj === "object" && obj !== null) {
    return true;
  }

  return false;
}

export function deepEqual(objFirst, objSec) {
  if (objFirst === objSec) {
    return true;
  }

  if (
    (objFirst === undefined && objSec !== undefined) ||
    (objFirst !== undefined && objSec === undefined)
  ) {
    return false;
  }

  const obj1 = JSON.parse(JSON.stringify(objFirst));
  const obj2 = JSON.parse(JSON.stringify(objSec));

  if (isObject(obj1) && isObject(obj2)) {
    if (Array.isArray(obj1) && Array.isArray(obj2)) {
      if (obj1.length === obj2.length) {
        obj1.sort((a, b) => +b?.identifier - +a?.identifier);
        obj2.sort((a, b) => +b?.identifier - +a?.identifier);

        for (let i = 0; i < obj1.length; i++) {
          if (!deepEqual(obj1[i], obj2[i])) {
            return false;
          }
        }
      } else {
        return false;
      }
    }

    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }

    for (let prop in obj1) {
      if (!deepEqual(obj1[prop], obj2[prop])) {
        return false;
      }
    }

    return true;
  }
}
