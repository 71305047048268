export const themesComponents = {
  "PM_brand": [
    "CardList",
    "CardListModal",
    "ChallengeComplete",
    "ChallengeForm",
    "ChallengeHeader",
    "ChallengeQr",
    "CryptoContent",
    "CryptoForm",
    "CustomInfoBlock",
    "DropdownHeader",
    "DropdownList",
    "DropdownSearch",
    "ExpirationDate",
    "FailPage",
    "Form",
    "FormHeader",
    "FormInfoBlock",
    "InputCvv",
    "InputSavedCard",
    "Instruction",
    "ManageCard",
    "Notification",
    "PendingLoader",
    "RememberMe",
    "SavedCard",
    "StatusLoader",
    "SuccessPage",
    "Button",
    "Field"
  ],
  "default": [
    "CardList",
    "ChallengeComplete",
    "ChallengeForm",
    "ChallengeHeader",
    "ChallengeQr",
    "CryptoContent",
    "CryptoForm",
    "CustomerInput",
    "DropdownHeader",
    "DropdownList",
    "DropdownSearch",
    "ExpirationDate",
    "FailPage",
    "Form",
    "FormHeader",
    "FormInfoBlock",
    "InputCvv",
    "InputSavedCard",
    "Instruction",
    "ManageCard",
    "Notification",
    "PendingLoader",
    "RememberMe",
    "StatusLoader",
    "SuccessPage",
    "TooltipDate",
    "Button",
    "Field"
  ],
  "kombine": [
    "CardList",
    "ChallengeHeader",
    "FailPage",
    "FormHeader",
    "InputCvv",
    "InputSavedCard",
    "ManageCard",
    "RememberMe",
    "SuccessPage"
  ]
};