import { ThemeConfig } from "../interfaces/configInterface";

const defaultThemeConfig: ThemeConfig = {
  isHideFormHeaderDescription: false,
  cardIconPass: false,
  showCardIcon: true,
  innerInputRememberMeShow: false,
  showRememberMeTooltip: false,
  cardHolderValidation: "default",
  replacedFormPaymentAmountButton: true,
  isHideBackToActionTitle: false,
  isHideTransactionId: false,
  isHideDescription: false,
  isShowCurrencyCopy: false,
  cardHolderInputPlaceHolder: "nameOnCard",
  formButtonTitle: "pay",
  formButtonCurrency: false,
  showDateTooltip: true,
  cardInputPlaceHolder: "•••• •••• •••• ••••",
  inputCardLabel: "pan",
  inputCardHolderLabel: "nameOnCard",
  inputDateLabel: "asquad.exp",
  hasModalAlertForClearCardInfo: true,
};

export default defaultThemeConfig;
