import { getPrefixAndRegexp } from "./utils";

// Specific functions for payment methods
export const METHOD_TYPE_UNIQ_OPERATIONS = {
  mobile_money: {
    filter: ({ currentState, data }) => {
      const { phone } = currentState?.mobile_money || {};

      if (!phone) {
        return data;
      }

      const filteredMethods = data.filter((item) => {
        if (item?.currency !== currentState?.currency) return false;

        const regexp = getPrefixAndRegexp(
          item?.currency,
          item?.channel?.ui_code,
        )?.regexp;

        return regexp ? regexp.test(phone) : false;
      });

      return filteredMethods.length > 0 ? filteredMethods : data;
    },
  },
};

export const GET_CUSTOM_ERROR_METHOD_ERROR_MESSAGE = (
  method,
  provider,
  error_code,
) => {
  const methodLower = method?.toLowerCase();
  const providerLower = provider?.toLowerCase();
  const customErrorMessages = {
    bank_transfer_japan: {
      jeton: {
        1021: {
          title: "pleaseTryAgainBankCannotProcess",
        },
      },
    },
  };

  return (
    customErrorMessages?.[methodLower]?.[providerLower]?.[error_code] ??
    customErrorMessages?.[methodLower]?.[error_code] ??
    null
  );
};

export default {
  METHOD_TYPE_UNIQ_OPERATIONS,
  GET_CUSTOM_ERROR_METHOD_ERROR_MESSAGE,
};
